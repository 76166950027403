import React from "react";
import Image from "../../elements/Image";
import Progress from "../../elements/Progress";

const Preview = ({
  fancyBox,
  data,
  multiple,
  removeFile = () => {},
  uploadPercentage,
}) => {
  const fancybox = {};
  if (data || (data && Array.isArray(data) && data?.length > 0)) {
    fancybox[`data-fancybox`] = "gallery";
  }

  return (
    <>
      {(data || (Array.isArray(data) && data?.length > 0)) && (
        <div className="flex flex-wrap gap-2 items-center my-5">
          {multiple ? (
            data?.map((file, i) => (
              <div className="relative w-32 h-32" key={i?.toString()}>
                <div className="w-full h-full overflow-hidden border border-slate-200 bg-white rounded-md">
                  {fancyBox ? (
                    <a
                      {...fancybox}
                      href={
                        file instanceof File ? URL.createObjectURL(file) : file
                      }
                    >
                      <Image
                        src={
                          file instanceof File
                            ? URL.createObjectURL(file)
                            : file
                        }
                        width={"100%"}
                        height={"100%"}
                        effect={"blur"}
                        classes={"object-cover"}
                      />
                    </a>
                  ) : (
                    <Image
                      src={
                        file instanceof File ? URL.createObjectURL(file) : file
                      }
                      width={"100%"}
                      height={"100%"}
                      effect={"blur"}
                      classes={"object-cover"}
                    />
                  )}
                </div>
                {(!uploadPercentage || uploadPercentage == 0) && (
                  <button
                    onClick={() => removeFile({ data, index: i, multiple })}
                    type="button"
                    className="w-5 h-5 rounded-full bg-white shadow absolute -top-2.5 -right-2.5 text-red-500 text-sm hover:bg-red-500 hover:text-white transition-all"
                  >
                    <i className="fa-regular fa-fw fa-xmark"></i>
                  </button>
                )}
                {file instanceof File && uploadPercentage > 0.5 && (
                  <div className="absolute rounded p-2 top-0 left-0 w-full h-full flex flex-col justify-center items-center backdrop-blur-[5px]">
                    <Progress progress={uploadPercentage} />
                    <div className="text-xs text-center mt-[2px]">
                      Uploading...{uploadPercentage}%
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="relative w-28 h-28">
              <div className="w-full h-full overflow-hidden border border-slate-200 bg-white rounded-md">
                {fancyBox ? (
                  <a
                    {...fancybox}
                    href={
                      data instanceof File ? URL.createObjectURL(data) : data
                    }
                  >
                    <Image
                      src={
                        data instanceof File ? URL.createObjectURL(data) : data
                      }
                      width={"100%"}
                      height={"100%"}
                      effect={"blur"}
                      classes={"object-cover"}
                    />
                  </a>
                ) : (
                  <Image
                    src={
                      data instanceof File ? URL.createObjectURL(data) : data
                    }
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-cover"}
                  />
                )}
              </div>
              <button
                onClick={() => removeFile({ data, multiple })}
                type="button"
                className="w-5 h-5 rounded-full bg-white shadow absolute -top-2.5 -right-2.5 text-red-500 text-sm hover:bg-red-500 hover:text-white transition-all"
              >
                <i className="fa-regular fa-fw fa-xmark"></i>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Preview;
