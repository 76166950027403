import React, { useEffect, useState } from "react";
import { setTitle } from "../../helpers/MetaTag";
import HeadingWrapper from "../../components/common/HeadingWrapper";
import Users from "./users/Index";
import { fetchData } from "../../helpers/fetchData";
import Counter from "../../common/countDown/CountDown";
import Recentdailytask from "../../components/Dashboard/Recentdailytask";
import { useNavigate } from "react-router-dom";
import RecentMonthlytask from "../../components/Dashboard/RecentMonthlytask";
import Dropdown from "../../components/Dashboard/monthDropdown/MonthDropDown";
import RecentDiaryTask from "../../components/Dashboard/RecentDiaryTask";
import { addPrefixOnNumber } from "../../utils/function";

function DashBoard() {
  setTitle("Journal | Dashboard");
  const [count, setCount] = useState({ isLoding: false });
  const navigate = useNavigate();
  const tableHeadDashbord = [
    {
      _id: crypto.randomUUID(),
      name: "Si. no.",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Image",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "First name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Last name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Email",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Action",
      align: "left",
      isSort: false,
      isFilter: false,
    },
  ];
  const getCount = async () => {
    setCount((prev) => ({ isLoding: true }));
    let resp = await fetchData("get", "/admin/count");
    if (resp?.data?.status === 200) {
      setCount((prev) => ({ ...prev, isLoding: false, ...resp?.data?.data }));
    } else {
      setCount((prev) => ({ isLoding: false }));
    }
  };
  useEffect(() => {
    getCount();
  }, []);

  const boxDetails = [
    {
      title: "Total Admins",
      value: count?.adminCount,
      route: "/users",
    },
    {
      title: "Total Users",
      value: count?.userCount,
      route: "/users",
    },
    {
      title: "Daily Task",
      value: count?.taskDaily,
      route: "/daily",
    },
    {
      title: (
        <div className="flex gap-2">
          <span>Monthly</span>
          <span className="text-violet-800">Daily Task</span>
        </div>
      ),
      value: count?.taskMonthlyDaily,
      route: "/months",
    },
    {
      title: (
        <div className="flex gap-2">
          <span>Monthly</span>
          <span className="text-violet-800">BirthDay</span>
        </div>
      ),
      value: count?.taskMonthlyBirthDay,
      route: "/months",
    },
    {
      title: (
        <div className="flex gap-2">
          <span>Monthly</span>
          <span className="text-violet-800">Event</span>
        </div>
      ),
      value: count?.taskMonthlyEvent,
      route: "/months",
    },
    {
      title: (
        <div className="flex gap-2">
          <span>Monthly</span>
          <span className="text-violet-800">Monthly</span>
        </div>
      ),
      value: count?.taskMonthlyMonthly,
      route: "/months",
    },
    {
      title: "Diary ",
      value: count?.taskDiary,
      route: "/diary",
    },
  ];

  const [dropDownData, setDropDownData] = useState([
    { label: "Daily", value: "daily", id: crypto.randomUUID() },
    {
      label: "Monthly",
      value: "monthly",
      id: crypto.randomUUID(),
    },
    {
      label: "Events",
      value: "events",
      id: crypto.randomUUID(),
    },
    {
      label: "Birthday",
      value: "birthday",
      id: crypto.randomUUID(),
    },
  ]);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedType, setSelectedtype] = useState({});
  useEffect(() => {
    setSelectedtype(dropDownData[0]);
  }, [dropDownData]);

  return (
    <>
      <HeadingWrapper title={"Dashboard"} />
      <div className="flex text-2xl">
        {/* {addPrefixOnNumber(count?.adminCount)} */}
      </div>
      <div className=" sections flex flex-row gap-4 justify-between flex-wrap">
        {boxDetails?.map((item, index) => (
          <div
            className="bg-white shadow-md rounded-lg p-4 flex flex-col justify-between w-full md:w-1/5 cursor-pointer h-28"
            onClick={() => navigate(item.route)}
          >
            <h2 className="text-lg font-semibold mb-2">{item?.title}</h2>
            <div className="text-3xl font-bold text-indigo-800">
              <Counter from={0} to={item?.value || 0} />
            </div>
          </div>
        ))}
      </div>
      <div className="bg-gray-300 rounded-lg">
        <div className="tableSection flex mt-4  px-4 py-3 gap-2 rounded-md">
          <div className="user w-1/2  ">
            <div className="text-xl font-bold  bg-indigo-800 text-white rounded-md px-8 py-2">
              <h1>Recent Users</h1>
            </div>
            <div className="h-96 overflow-scroll scrollbar relative mt-4">
              <Users isDashBoard={true} />
            </div>
          </div>
          <div className="user w-1/2 ">
            <div className="text-xl font-bold bg-indigo-800 text-white rounded-md px-8 py-2">
              <h1>Recent Daily Tasks</h1>
            </div>
            <div className="h-96 overflow-scroll scrollbar relative mt-4">
              <Recentdailytask />
            </div>
          </div>
        </div>
        <div className="tableSection flex   px-4 pb-4 gap-2 rounded-md">
          <div className="user w-1/2  ">
            <div className="text-xl font-bold flex justify-between  bg-indigo-800 text-white rounded-md px-8 py-2">
              <h1>Recent Monthly Task</h1>
              <div>
                <Dropdown
                  dropdownData={dropDownData}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  selected={selectedType || dropDownData[0]}
                  setSelected={setSelectedtype}
                />
              </div>
            </div>
            <div className="h-96 overflow-scroll scrollbar relative mt-4">
              <RecentMonthlytask selectedType={selectedType} />
            </div>
          </div>
          <div className="user w-1/2 ">
            <div className="text-xl font-bold bg-indigo-800 text-white rounded-md px-8 py-2">
              <h1>Recent Diary Tasks</h1>
            </div>
            <div className="h-96 overflow-scroll scrollbar relative mt-4">
              <RecentDiaryTask />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashBoard;
