import React from "react";
// import { getOrdinalSuffix } from "../../helpers";
import { getOrdinalSuffix } from "../utils/function";
import { months } from "../utils/constant";
// import { months } from "../../constant";

const SectionHeader = ({ activeDate, selectedMonth, selectedYear, title }) => {
  return (
    <div className="h-11 w-full flex rounded-t-md bg-indigo-300/30">
      <div className="h-full w-1/5 flex-grow-0 flex items-center text-indigo-900 px-3 text-2xl font-bold">
        {title}
      </div>
      <div className="h-full w-full flex-grow flex items-center justify-end px-3 text-indigo-900 text-base font-semibold">
        {activeDate
          ? getOrdinalSuffix(activeDate) + ","
          : getOrdinalSuffix(new Date().getDate()) + ","}{" "}
        {months[selectedMonth]} {selectedYear}
      </div>
    </div>
  );
};

export default SectionHeader;
