// import { apiRequest } from "../utils/async/apiRequest";
import { fetchData } from "../helpers/fetchData";
export const getMonthList = async (params) => {
  try {
    const res = await fetchData("get", "admin/task-list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
// {{live}}admin/task-list?year=2024&userId=66190aaef1ec555d371fb3f2&type=monthly
export const addNoteAndTaskInMonth = async (params) => {
  try {
    const res = await fetchData(
      `${params?.taskElementId ? "put" : "post"}`,
      `${params?.taskElementId ? "admin/edit-task" : "admin/add"}`,
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
