import React, { useCallback, useEffect, useRef, useState } from "react";
// import Button from "../../form/Button";
import NotesItem from "./NotesItem";
// import {
//   addNoteAndTaskInMonth,
//   getMonthList,
// } from "../../../service/monthService";
import moment from "moment";
import { toast } from "react-toastify";
// import DeleteModal from "../../common/DeleteModal";
// import NotesLoader from "../../loader/notes/NotesLoader";
// import Modal from "../../elements/Modal";
// import EventAndBirthdayModal from "../../modal/EventAndBirthdayModal";
import Button from "../../../../components/form/Button";
import {
  addNoteAndTaskInMonth,
  getMonthList,
} from "../../../../services/monthService";
import DeleteModal from "../../../../components/common/DeleteModal";
import NotesLoader from "../../../../common/Loder/NoteLoders/NoteLoder";
import Modal from "../../../../common/Modal";
import EventAndBirthdayModal from "../../../../components/EventAndBirthdayModal";

const EventsNote = ({
  title = "",
  type = "",
  selectedYear,
  selectedMonth,
  abbBtnTooltipLabel = "",
  selectedUser,
}) => {
  const scrollSec = useRef(null);
  let previousTaskHeight = 0;

  const [loaded, setLoaded] = useState(true);
  const [itemDelete, setItemDelete] = useState({
    deleteComplete: true,
    openModal: false,
    data: {
      apiEndpoint: "admin/task-delete",
      targetId: undefined,
      method: "post",
    },
  });
  const [complete, setComplete] = useState({
    index: null,
    noteIndex: null,
    isComplete: true,
  });
  const [content, setContent] = useState([]);
  const [eventModal, setEventModal] = useState({
    isOpen: false,
  });

  const [formData, setFormData] = useState({});
  const getData = useCallback(() => {
    if (selectedYear && selectedMonth) {
      setLoaded(false);
      getMonthList({
        type: "monthly",
        subType: type,
        year: selectedYear?.toString(),
        month: (selectedMonth + 1)?.toString(),
        userId: selectedUser?.value,
      }).then((res) => {
        if (res?.docs?.status === 200) {
          if (
            res?.docs?.data &&
            Array.isArray(res?.docs?.data) &&
            res?.docs?.data?.length > 0
          ) {
            setContent(
              res?.docs?.data.sort((a, b) => Number(a.day) - Number(b.day))
            );
          } else {
            setContent([]);
          }
        }
        setLoaded(true);
      });
    }
  }, [selectedYear, selectedMonth, selectedUser?.value]);

  useEffect(() => {
    if (selectedUser.value) {
      getData();
    }
  }, [getData, selectedUser?.value]);

  const addEventHandler = (data) => {
    const date = data?.date;
    const year = new Date(date).getFullYear();
    if (date) {
      const day = moment(date).format("DD");
      const time = new Date(date).toLocaleTimeString();
      saveNote({ ...data, date, day, time, userId: selectedUser?.value });
    }
  };

  useEffect(() => {
    if (scrollSec.current) {
      function scrollBottom() {
        if (previousTaskHeight < scrollSec.current.scrollHeight) {
          scrollSec.current.scrollTop =
            scrollSec.current.scrollHeight - scrollSec.current.clientHeight;
          previousTaskHeight = scrollSec.current.scrollHeight;
        }
      }
      setTimeout(() => {
        scrollBottom();
      }, 200);
      clearTimeout();
    }
  }, [content]);

  const saveNote = (data) => {
    console.log(data);

    const { day, time } = data;
    let date = new Date();
    date.setFullYear(selectedYear);
    date.setMonth(selectedMonth);

    const new_data = {
      type: "monthly",
      subType: type,
      date: moment(data.date).format("YYYY-MM-DD"),
      // date: data?.date,
      userId: selectedUser?.value,
    };

    if (data?._id) {
      new_data["name"] = data?.name;
      new_data["taskElementId"] = data?._id;
    } else {
      new_data["task[0][name]"] = data?.name;
      new_data["task[0][dateTime]"] = data?.date?.toISOString();
    }
    console.log({ formData });
    if (!data.name) {
      toast.warn("Title is required");
      return;
    }
    if (!data.date) {
      toast.warn("Date and time is required");
      return;
    }

    setComplete({
      index: data?.index,
      noteIndex: data?.noteIndex,
      isComplete: false,
    });

    addNoteAndTaskInMonth(new_data).then((res) => {
      if (res?.status === 201 || res?.status === 200) {
        const dayElementIndex = content.findIndex(
          (element) => element?.day === day
        );

        if (res?.status == 201) {
          if (dayElementIndex != -1) {
            setContent((pre) => {
              pre[dayElementIndex].task = res?.data?.task;
              return [...pre];
            });
          } else {
            setContent((pre) => [
              ...pre,
              {
                _id: res?.data?._id,
                type: res?.data?.type,
                year: res?.data?.year,
                month: res?.data?.month,
                day,
                task: res?.data?.task,
              },
            ]);
          }
          toast.success(res?.message);
        }
        if (res?.status == 200) {
          setContent((pre) =>
            pre?.map((item) =>
              item?.day == res?.data?.day
                ? {
                    ...res?.data,
                    task: res?.data?.task,
                  }
                : item
            )
          );
        }
        setFormData({});
        setEventModal({ isOpen: false });
      } else {
        toast.error(res?.message);
      }
      setComplete({
        index: null,
        noteIndex: null,
        isComplete: true,
      });
    });
  };

  const removeHandler = (data) => {
    if (data?.data?._id) {
      setItemDelete((pre) => ({
        ...pre,
        openModal: true,
        data: {
          apiEndpoint: "admin/task-delete",
          targetId: undefined,
          method: "put",
          data: data?.data,
          payload: {
            body: {
              entryId: data?.content?._id,
              taskElementId: data?.data?._id,
              userId: selectedUser?.value,
            },
          },
        },
      }));
    } else {
      const find_content = content.find(
        (item) => item?.day == data?.content?.day
      );
      if (find_content?.task?.length > 1) {
        setContent((pre) => {
          pre[data?.data?.index].task = pre[data?.data?.index].task?.filter(
            (_, i) => i !== data?.data?.taskIndex
          );
          return [...pre];
        });
      } else {
        setContent((pre) =>
          pre?.filter((item) => item?.day !== find_content?.day)
        );
      }
    }
  };

  const getResponseHandlerAfterDelete = (res) => {
    if (res?.data?.status == 200) {
      setContent((pre) => {
        let new_arr = [...pre];
        new_arr[res?.data?.data?.index].task = new_arr[
          res?.data?.data?.index
        ].task?.filter((item, i) => i != res?.data?.data?.taskIndex);
        return new_arr;
      });
    }

    setItemDelete({
      deleteComplete: true,
      openModal: false,
      data: {
        apiEndpoint: "admin/task-delete",
        targetId: undefined,
        method: "post",
      },
    });
  };

  const getTooltipContent = (type) => {
    if (type === "monthly") {
      return "Add Goal";
    } else if (type === "event") {
      return "Add Event";
    } else if (type === "birthday") {
      return "Add Birthday";
    }
  };

  return (
    <>
      <div className="border border-indigo-900 rounded-md overflow-hidden shadow-md">
        <div className="relative h-9 text-center flex items-center justify-center bg-indigo-900 text-white font-semibold">
          <span>{title}</span>
          <Button
            buttonType={"button"}
            buttonFunction={() => {
              setEventModal({ isOpen: true });
              setFormData({});
            }}
            buttonIcon={"fa-regular fa-plus"}
            buttonIconPosition={"left"}
            buttonLabel={""}
            buttonClasses={
              "w-8 h-8 ml-auto absolute top-[1px] right-[1px] bg-transparent"
            }
            tooltip={{
              show: true,
              label: abbBtnTooltipLabel,
              id: abbBtnTooltipLabel.split(" ").join("-"),
            }}
          />
        </div>
        <div
          ref={scrollSec}
          className={`min-h-[100px] p-3 px-4 flex flex-col ${
            loaded ? "gap-y-3" : "gap-y-1"
          } max-h-96 overflow-y-auto scrollbar`}
        >
          {loaded &&
            content &&
            Array.isArray(content) &&
            content?.length > 0 &&
            content?.map((item, i) =>
              item?.task?.map((task, ind) => (
                <NotesItem
                  key={ind?.toString()}
                  index={i}
                  taskIndex={ind}
                  onRemove={(data) => {
                    removeHandler({ data, content: item });
                  }}
                  data={task}
                  loading={
                    complete?.index == i && complete?.noteIndex == ind
                      ? !complete?.isComplete
                      : false
                  }
                  type={type}
                  onSave={(value) => saveNote({ ...value, noteIndex: ind })}
                  date={moment(
                    new Date(selectedYear, selectedMonth, Number(item?.day))
                  ).format("MM-DD-YYYY")}
                  dateTime={task?.dateTime}
                  showDate
                  showTime
                  saveEmpty={false}
                />
              ))
            )}
          {loaded &&
            content &&
            Array.isArray(content) &&
            content?.map((item) => item?.task).flat(Infinity)?.length == 0 && (
              <div className="w-full h-[90px] flex flex-col items-center justify-center">
                <img src={"/images/no_data.svg"} alt="no-task" />
                <div className="text-slate-300">No Data</div>
              </div>
            )}
          {!loaded && <NotesLoader />}
        </div>
      </div>
      <DeleteModal
        setIsOpen={() =>
          setItemDelete({
            deleteComplete: true,
            openModal: false,
            data: {
              apiEndpoint: "admin/task-delete",
              targetId: undefined,
              method: "post",
            },
          })
        }
        isOpen={itemDelete?.openModal}
        loading={!itemDelete?.deleteComplete}
        data={itemDelete?.data}
        getResponse={getResponseHandlerAfterDelete}
        deleteFunction={() => {
          setItemDelete((pre) => ({
            ...pre,
            deleteComplete: false,
          }));
        }}
      />
      <Modal
        size="md"
        isOpen={eventModal?.isOpen}
        setIsOpen={() => {
          setEventModal({ isOpen: false });
        }}
        modalClass={"w-1/3"}
        onCancel={() => setEventModal({ isOpen: false })}
        onSucess={() => addEventHandler(formData)}
      >
        <div className="text-xl font-semibold mb-3 text-center">
          {getTooltipContent(type)}
        </div>
        <EventAndBirthdayModal
          setFormData={setFormData}
          formData={formData}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          // onClose={() => setEventModal({ isOpen: false })}
          onSave={() => addEventHandler(formData)}
          loading={!complete?.isComplete}
        />
      </Modal>
    </>
  );
};

export default React.memo(EventsNote);
