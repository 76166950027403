import React, { useEffect, useMemo, useRef, useState } from "react";
import Texteditor from "./texteditor/Texteditor";
import "react-quill/dist/quill.snow.css";
import ListLoader from "../loder/listLoader/ListLoader";
import { removeTag } from "../../utils/function";

const HideableTexteditor = ({
  data = {},
  loading = false,
  onSubmit = () => {},
}) => {
  const quillRef = useRef(null);
  const [showEditor, setShowEditor] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(data?.name);
  }, [data]);

  useEffect(() => {
    if (removeTag(value) || removeTag(data?.name)) {
      setShowEditor(false);
    } else {
      setShowEditor(true);
    }
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (quillRef.current && !quillRef.current.contains(event.target)) {
        if (removeTag(value)) {
          setShowEditor(false);
        }
        if (removeTag(data?.name) !== removeTag(value)) {
          onSubmit(value);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [value, data]);

  const handleEditorClick = () => {
    if (!showEditor) {
      setShowEditor(true);
    }
  };

  return (
    <div ref={quillRef}>
      {!loading && (
        <>
          {!showEditor ? (
            <div
              className="ql-snow min-h-[300px]"
              onClick={() => handleEditorClick()}
            >
              <div
                className="ql-editor !px-0"
                dangerouslySetInnerHTML={{ __html: value }}
              ></div>
            </div>
          ) : (
            <Texteditor value={value} onChange={setValue} />
          )}
        </>
      )}
      {loading && (
        <div className="my-5">
          <ListLoader />
        </div>
      )}
    </div>
  );
};

export default HideableTexteditor;
