import React from "react";
import Th from "./Th";

const Thead = ({ tableHeadData }) => {
  return (
    <>
      <thead>
        <tr className="bg-slate-50 text-slate-700 text-xs leading-none border-b border-slate-200">
          {tableHeadData.map((item, index) => (
            <Th
              key={item._id}
              name={item.name}
              nameAlign={item.align}
              isSort={item.isSort}
              isFilter={item.isFilter}
              type={item.type}
              dropDown={item.dropDown}
              idArray={item.idArray}
              handleCheckboxChange={item.handleCheckboxChange}
              handleSortQuery={item.changeSortQuery}
              checkboxChecked={item.checked}
              onCheckboxChange={() =>
                item.onCheckboxChange
                  ? item.onCheckboxChange(item, true)
                  : () => {}
              }
              index={index}
            />
          ))}
        </tr>
      </thead>
    </>
  );
};

export default Thead;
