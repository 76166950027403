import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Texteditor = ({
  value = "",
  onChange = () => {},
  placeholder = "Write your content....",
  label = "",
  labelClasses = "",
  errorType = "",
  errorText = "",
}) => {
  const values = {};

  const modules = {
    toolbar: [[{ header: [1, 2, false] }], ["bold", "italic", "underline"]],
  };

  if (value) {
    values["value"] = value;
  }

  return (
    <>
      {label && (
        <div
          className={"text-sm font-medium text-slate-500 mb-1 " + labelClasses}
        >
          {label}
        </div>
      )}
      <ReactQuill
        placeholder={placeholder}
        theme="snow"
        modules={modules}
        {...values}
        onChange={(val) => {
          onChange(val);
        }}
      />
      {errorType && errorType === "error" && (
        <div className="text-xs text-red-600 mt-1">
          <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "warning" && (
        <div className="text-xs text-indigo-600 mt-1">
          <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "success" && (
        <div className="text-xs text-green-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "info" && (
        <div className="text-xs text-sky-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
          {errorText}
        </div>
      )}
    </>
  );
};

export default Texteditor;
