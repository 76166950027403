import React from "react";
import TableFilter from "./TableFilter";
import TableSort from "./TableSort";
import Checkbox from "../../form/Checkbox";

const Th = ({
  name,
  nameAlign,
  isSort,
  isFilter,
  handleSortQuery,
  dropDown,
  idArray,
  handleCheckboxChange,
  onCheckboxChange,
  type,
  checkboxChecked,
  index,
  ...props
}) => {
  const align = {
    left: "justify-start",
    right: "justify-end",
    center: "justify-center",
    "": "justify-start",
  };

  return (
    <th className={`py-4 px-2 text-left max-w-[240px] `}>
      <div className={`flex items-center w-full  ${align[nameAlign]} `}>
        <div className={`text-[11px] uppercase font-normal text-slate-400 `}>
          {name}
        </div>
        {type === "checkbox" && (
          <Checkbox isChecked={checkboxChecked} onChange={onCheckboxChange} />
        )}
        <div className="ml-auto flex justify-end items-center">
          {isSort && (
            <TableSort
              handleSortQuery={handleSortQuery}
              sortState={"nostate"}
            />
          )}
          {isFilter && (
            <TableFilter
              idArray={idArray}
              dropDown={dropDown}
              handleCheckboxChange={handleCheckboxChange}
              xPlacement={"right"}
            />
          )}
        </div>
      </div>
    </th>
  );
};

export default Th;
