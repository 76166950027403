import React, { useEffect, useState } from "react";
// import { setTitle } from "../helpers/MetaTag";
import CalenderLayout from "../months/calendarLayout/CalendarLayout";
import DiarySection from "./DiarySection";
import { setTitle } from "../../../helpers/MetaTag";
import Select from "../../../components/form/Select";
import { withTryCatch } from "../../../utils/function";
import { fetchData } from "../../../helpers/fetchData";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../components/form/Button";

setTitle("Journal 365 | Diary");

const Diary = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(
    currentYear ? currentYear : ""
  );
  const [selectedUser, setSelecteduser] = useState({});
  const [usersDropDown, setUsersDropDown] = useState([]);
  const { userid } = useParams();
  const getUsers = withTryCatch(async () => {
    let users = await fetchData("get", "admin/user-list", {
      queries: { limit: 500 },
    });
    if (users.data.docs.status === 200) {
      let updatedUser = users?.data?.docs?.data?.map((item, index) => ({
        _id: item._id,
        label:
          (item?.firstName ? item?.firstName : "") +
          " " +
          (item?.lastName ? item?.lastName : ""),
        value: item._id,
      }));
      setUsersDropDown(updatedUser);
      if (!userid) {
        setSelecteduser(updatedUser[0]);
      } else {
        setSelecteduser({ value: userid });
      }
    } else {
      toast.warn(users?.data?.docs?.message || "Something went wrong");
    }
  });
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <div className="flex !ml-auto  items-center justify-end gap-2 mb-4">
        <div className="relative w-48">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white !ml-auto"}
            label="Select User"
            placeholder="Select user"
            dropdownData={usersDropDown}
            onChange={(val) => {
              setSelecteduser(val);
            }}
            selectedValue={selectedUser.value}
            inputName="noOfEmployees"
          />
        </div>
      </div>

      <CalenderLayout
        currentYear={currentYear}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        setCurrentYear={setCurrentYear}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}
        selectedUser={selectedUser}
      >
        <DiarySection
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          selectedUser={selectedUser}
        />
      </CalenderLayout>
    </>
  );
};

export default Diary;
