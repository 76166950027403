import { Tooltip } from "react-tooltip";
import CreateRoutes from "./routes";
function App() {
  return (
    <>
      <CreateRoutes />;
      <Tooltip
        id="my-tooltip"
        className="bg-indigo-700"
        data-tooltip-variant="success"
      />
    </>
  );
}

export default App;
