// import { apiRequest } from "../utils/async/apiRequest";

import { fetchData } from "../helpers/fetchData";

export const getDiaryList = async (params) => {
  try {
    const res = await fetchData("get", "admin/task-detail", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addDiary = async (params, uploadPer = () => {}) => {
  try {
    const res = await fetchData(
      "post",
      "admin/add",
      {
        body: params,
      },
      "multipart/form-data",
      (count) => {
        uploadPer(count);
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
