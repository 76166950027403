import ReactPaginate from "react-paginate";

const Pagination = ({
  totalPages = 5,
  currentPage = 0,
  onPageChange = () => {},
}) => {
  const handlePageChange = (selectedPage) => {
    onPageChange(selectedPage.selected);
  };

  return (
    <div>
      <ReactPaginate
        className={"flex items-center flex-wrap gap-1"}
        pageClassName={
          "w-10 h-10 bg-white rounded-md overflow-hidden border border-slate-200"
        }
        pageLinkClassName={
          "px-2 rounded-lg overflow-hidden bg-transparent w-full h-full flex items-center justify-center text-sm"
        }
        onPageChange={handlePageChange}
        pageCount={totalPages}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        activeClassName={"!bg-teal-500 text-white"}
        forcePage={currentPage}
        previousLabel={"Previous"}
        previousClassName={"w-auto h-10"}
        previousLinkClassName={
          "px-2 border border-slate-200 rounded-md overflow-hidden w-full h-full flex items-center justify-center uppercase font-medium text-xs bg-white"
        }
        nextLabel={"Next"}
        nextClassName={"w-auto h-10"}
        nextLinkClassName={
          "px-2 border border-slate-200 rounded-md overflow-hidden w-full h-full flex items-center justify-center uppercase font-medium text-xs bg-white"
        }
        breakLabel={"..."}
        containerClassName={"pagination"}
        disabledClassName="cursor-none"
        disabledLinkClassName={"opacity-50"}
      />
    </div>
  );
};

export default Pagination;
