import React, { useEffect, useState } from "react";
import NotesAction from "./NotesAction";
import NoteInput from "./NoteInput";
import Dot from "../../../../components/common/dotLoading/Dot";
// import moment from "moment";
// import Dot from "../../loader/dotLoading/Dot";
import moment from "moment-timezone";
import { calculateAge } from "../../../../utils/function";
const NotesItem = ({
  index = 0,
  taskIndex = 0,
  data = {},
  onRemove = () => {},
  onChange = () => {},
  onSave = () => {},
  date = new Date(),
  loading = false,
  type,
  dateTime,
  saveEmpty = true,
}) => {
  return (
    <div
      className={`bg-white p-2 pl-7 pr-7 rounded-md shadow hover:shadow-lg relative transition-all cursor-pointer group`}
    >
      <span className="bg-indigo-900 text-white flex p-1 justify-center items-center min-h-[24px] min-w-[24px] absolute -top-1 -left-1 text-xs rounded-[3px]">
        {index + 1}-{taskIndex + 1}
      </span>
      <div className="text-indigo-900 justify-center items-center h-6 w-6 absolute top-1 right-1 text-xs rounded-[3px] hidden group-hover:flex ">
        <NotesAction
          data={data}
          editAction={(data) => console.log(data)}
          deleteAction={(data) => onRemove(data)}
          taskIndex={taskIndex}
          index={index}
        />
      </div>
      {type !== "monthly" && (
        <div className="flex gap-2">
          <div className="py-[2px] mb-1 flex gap-2 text-xs w-fit px-2 ml-auto rounded bg-indigo-900 text-white items-center justify-center">
            <span>
              <i className="fa-light fa-calendar-days"></i>
            </span>
            <span>{moment(dateTime.split("T")[0]).format("MM-DD-YYYY")}</span>
          </div>
          <div className="py-[2px] mb-1 flex gap-2 text-xs w-fit px-2 rounded bg-indigo-900 text-white items-center justify-center">
            <span>
              <i className="fa-regular fa-clock"></i>
            </span>
            <span>{moment.utc(dateTime).local().format("hh:mm A")}</span>
          </div>
          {type == "birthday" && (
            <div className="py-[2px] mb-1 flex gap-2 text-xs w-fit px-2 rounded bg-indigo-900 text-white items-center justify-center">
              <span>
                <i className="fa-light fa-cake-candles"></i>
              </span>
              <span>
                {calculateAge(
                  moment(dateTime.split("T")[0]).format("MM-DD-YYYY")
                )}
              </span>
            </div>
          )}
        </div>
      )}

      <NoteInput
        data={data}
        onChange={(e) => onChange(e)}
        onSave={(data) => onSave({ ...data, date })}
        taskIndex={taskIndex}
        index={index}
        saveEmpty={saveEmpty}
      />
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full">
          <Dot />
        </div>
      )}
    </div>
  );
};

export default NotesItem;
