import React, { useEffect, useRef, useState } from "react";
// import Modal from "../../elements/Modal";
import AddTask from "./AddTask";
// import {
//   addNoteAndTaskInDaily,
//   addSubTaskInDaily,
// } from "../../../service/dailyService";
import {
  addNoteAndTaskInDaily,
  addSubTaskInDaily,
} from "../../services/dailyService";
import moment from "moment";
import { toast } from "react-toastify";
import Button from "../form/Button";
import NoteInput from "../../pages/dashboard/months/monthlyGoalSection/NoteInput";
import Dot from "../common/dotLoading/Dot";
import Modal from "../common/modal-buddho/Modal";
import Input from "../form/Input";
import { tooltipData } from "../../utils/constant";
import GoalAction from "./GoalAction";
import Checkbox from "../form/Checkbox";
import DeleteModal from "../common/DeleteModal";
import { goal_type } from "../../utils/enum";

const RenderdayByMonths = ({
  calender = {},
  monthIndex = 1,
  selectedYear = new Date().getFullYear(),
  note = [],
  setNoteAndTask = () => {},
  getCurrentDate = () => {},
  selectedUser,
}) => {
  const [addComplete, setAddComplete] = useState({
    note: true,
    task: true,
    status: { index: null, taskIndex: null, complete: true },
  });
  const [ModalOpen, setModalIsOpen] = useState({
    isOpen: false,
    target: null,
    editData: null,
  });
  const [itemDelete, setItemDelete] = useState({
    deleteComplete: true,
    openModal: false,
    data: {
      apiEndpoint: "admin/delete-all-subtask",
      targetId: undefined,
      method: "post",
    },
  });
  function onSaveNoteHandler(data) {
    const new_data = {
      type: "daily",
      subType: "daily",
      date: moment(new Date(selectedYear, monthIndex, data?.day)).format(
        "YYYY-MM-DD"
      ),
      userId: selectedUser?.value,
    };

    if (data?.taskId) {
      new_data[`taskElementId`] = data?.taskId;
      new_data[`name`] = data?.name;
    } else {
      new_data["task[0][name]"] = data?.name;
    }

    addNoteAndTaskInDaily(new_data).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        const updatedDaysMap = new Map();
        [res?.data].forEach((updatedDay) => {
          updatedDaysMap.set(updatedDay.day, {
            ...updatedDay,
            day: Number(updatedDay.day),
            note: updatedDay.task[0]?.name,
            taskId: updatedDay.task[0]?._id,
            tasks: updatedDay?.task[0]?.subTask?.map((item) => ({
              ...item,
              label: item?.name,
              checked: item?.complete ? true : false,
            })),
          });
        });

        setNoteAndTask((prevDays) => {
          updatedDaysMap?.forEach((item) => {
            delete item.task;
            prevDays[Number(item?.day - 1)] = item;
          });
          return [...prevDays];
        });
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    });
  }

  const onTaskStatusChange = (data) => {
    const new_data = {
      type: "daily",
      date: moment(new Date(selectedYear, monthIndex, data?.day)).format(
        "YYYY-MM-DD"
      ),
      taskElementId: data?.taskElementId,
      subTaskElementId: data?.subTaskId,
      completed: data?.value,
      userId: selectedUser?.value,
    };

    setAddComplete((pre) => ({
      ...pre,
      status: {
        complete: false,
        index: data?.index,
        taskIndex: data?.taskIndex,
      },
    }));
    addSubTaskInDaily(new_data).then((res) => {
      if (res?.status === 200) {
        const updatedDaysMap = new Map();
        [res?.data].forEach((updatedDay) => {
          updatedDaysMap.set(updatedDay.day, {
            ...updatedDay,
            day: Number(updatedDay.day),
            note: updatedDay.task[0]?.name,
            taskId: updatedDay.task[0]?._id,
            tasks: updatedDay?.task[0]?.subTask?.map((item) => ({
              ...item,
              label: item?.name,
              checked: item?.complete ? true : false,
            })),
          });
        });

        setNoteAndTask((prevDays) => {
          updatedDaysMap?.forEach((item) => {
            delete item.task;
            prevDays[Number(item?.day - 1)] = item;
          });
          return [...prevDays];
        });
        toast.success(res?.message);
        setModalIsOpen({
          isOpen: false,
          target: null,
          editData: null,
        });
      } else {
        toast.error(res?.message);
      }
      setAddComplete((pre) => ({
        ...pre,
        status: {
          complete: true,
          index: null,
          taskIndex: null,
        },
      }));
    });
  };

  const editTask = (data) => {
    setModalIsOpen({
      isOpen: true,
      target: data?.target,
      editData: data?.task,
    });
  };

  const addTask = (data) => {
    setModalIsOpen({ isOpen: true, target: data, editData: null });
  };

  // for subtask add ===========>
  const onSave = (data) => {
    const new_data = {
      type: "daily",
      date: moment(
        new Date(selectedYear, monthIndex, ModalOpen?.target?.day)
      ).format("YYYY-MM-DD"),

      userId: selectedUser?.value,
    };
    if (!data.taskName) {
      toast.warn("Goal name is required");
      return;
    }
    if (!data.targetPercent) {
      toast.warn("Target is required");
      return;
    }
    if (data?.goal_type === goal_type?.quantifiable) {
      if (ModalOpen?.editData?._id) {
        new_data["totalTime"] = data?.targetPercent;
        new_data["goalType"] = data.goal_type;
      } else {
        new_data["subTask[totalTime]"] = data?.targetPercent;
        new_data["subTask[goalType]"] = data?.goal_type;
      }
    } else {
      if (ModalOpen?.editData?._id) {
        new_data["totalTime"] = 0;
        new_data["goalType"] = data.goal_type;
      } else {
        new_data["subTask[totalTime]"] = 0;
        new_data["subTask[goalType]"] = data?.goal_type;
      }
    }

    if (ModalOpen?.editData && ModalOpen?.editData?._id) {
      new_data[`name`] = data?.taskName;
      new_data[`identicalId`] = ModalOpen?.editData?.identicalId;
    } else {
      new_data["subTask[name]"] = data?.taskName;
    }
    setAddComplete((pre) => ({ ...pre, task: false }));
    addSubTaskInDaily(new_data).then((res) => {
      if (res?.status === 200) {
        const updatedDaysMap = new Map();
        res?.data.forEach((updatedDay) => {
          updatedDaysMap.set(updatedDay.day, {
            ...updatedDay,
            day: Number(updatedDay.day),
            note: updatedDay.task[0]?.name,
            taskId: updatedDay.task[0]?._id,
            tasks: updatedDay?.task[0]?.subTask?.map((item) => ({
              ...item,
              label: item?.name,
            })),
          });
        });

        setNoteAndTask((prevDays) => {
          updatedDaysMap?.forEach((item) => {
            delete item.task;
            prevDays[Number(item?.day - 1)] = item;
          });
          return [...prevDays];
        });
        toast.success(res?.message);
        setModalIsOpen({
          isOpen: false,
          target: null,
          editData: null,
        });
      } else {
        toast.error(res?.message);
      }
      setAddComplete((pre) => ({ ...pre, task: true }));
    });
  };

  const removeHandler = (data) => {
    if (data && data?._id) {
      setItemDelete((pre) => ({
        ...pre,
        openModal: true,
        data: {
          apiEndpoint: "admin/delete-all-subtask",
          targetId: undefined,
          method: "put",
          data: data,
          contentType: "multipart/form-data",
          payload: {
            body: {
              type: "daily",
              taskElementId: data?.taskElementId,
              subTaskElementId: data?._id,
              identicalId: data?.identicalId,
              date: moment(
                new Date(selectedYear, monthIndex, data?.day)
              ).format("YYYY-MM-DD"),
            },
          },
        },
      }));
    }
  };

  const getResponseHandlerAfterDelete = (res) => {
    if (res?.data?.status == 200) {
      setNoteAndTask((prev) => {
        prev.map((item, index) => {
          item.tasks = item?.tasks?.filter(
            (taskItem, taskIndex) =>
              taskItem?.identicalId !== res?.data?.data?.identicalId
          );
        });
        return prev;
      });

      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }

    setItemDelete({
      deleteComplete: true,
      openModal: false,
      data: {
        apiEndpoint: "admin/delete-all-subtask",
        targetId: undefined,
        method: "post",
      },
    });
  };

  const [selectedDayIndex, setSelecetdDayIndex] = useState(
    new Date().getDate() - 1
  );

  const presentDayRef = useRef(null);

  useEffect(() => {
    if (selectedDayIndex && presentDayRef.current) {
      presentDayRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedDayIndex, presentDayRef.current]);
  return (
    <>
      <div className="w-full    ">
        <div className="flex gap-2 mb-3">
          <div className="w-32 flex-grow-0 bg-indigo-900 text-white py-1 text-center rounded">
            Days
          </div>
          <div className="w-[calc(100%-128px)] flex gap-2">
            <div className="w-1/2 bg-indigo-900 text-white py-1 text-center rounded">
              Daily Highlights
            </div>

            <div className="w-1/2 flex items-center bg-indigo-900 text-white py-1  rounded px-4">
              <span className="inline-block w-full text-center">
                Daily Goal
              </span>
              <i
                className="fa-solid fa-plus ml-auto cursor-pointer "
                data-tooltip-content={"Add Goal"}
                data-tooltip-id="my-tooltip"
                onClick={() => addTask({ day: Number(calender?.today) })}
              ></i>
            </div>
          </div>
        </div>

        <div className="overflow-y-auto scrollbar h-[60vh]">
          {note &&
            Array.isArray(note) &&
            note.map(({ day, note, tasks, taskId }, i) => (
              <div
                key={i?.toString()}
                className="flex gap-2 mb-2 group "
                ref={i === selectedDayIndex ? presentDayRef : null}
              >
                <div
                  className={`${
                    calender?.currentMonth == monthIndex &&
                    Number(calender?.today) == day
                      ? "!bg-indigo-900 [&>*]:!text-white"
                      : ""
                  } w-32 flex-grow-0 space-y-0.5 rounded py-2 group-odd:bg-indigo-100 group-even:bg-indigo-50 day ${
                    getCurrentDate(monthIndex, day) === "Saturday" ||
                    getCurrentDate(monthIndex, day) === "Sunday"
                      ? "weekend"
                      : ""
                  }`}
                >
                  <div className="date text-lg text-center text-indigo-900 font-bold">
                    {day}
                  </div>
                  <div className="day-label text-sm text-center text-indigo-900 font-bold tracking-wider">
                    {getCurrentDate(monthIndex, day)}
                  </div>
                </div>
                <div className="w-[calc(100%-128px)] flex gap-2">
                  <div className="w-1/2 rounded">
                    <div
                      className={`flex-grow h-full space-y-0.5 rounded py-2 group-odd:bg-indigo-100 group-even:bg-indigo-50 day ${
                        getCurrentDate(monthIndex, day) === "Saturday" ||
                        getCurrentDate(monthIndex, day) === "Sunday"
                          ? "weekend"
                          : ""
                      }`}
                    >
                      <div className="px-3 h-full">
                        <NoteInput
                          data={{ name: note }}
                          index={i}
                          onSave={(e) => {
                            onSaveNoteHandler({ ...e, day, taskId });
                          }}
                          selectedUser={selectedUser}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`w-1/2 rounded flex gap-1 overflow-hidden h-20 `}
                  >
                    {tasks && Array?.isArray(tasks) && tasks?.length > 0 && (
                      <>
                        <div className="flex gap-1 h-full w-full overflow-x-auto scrollbar">
                          {tasks?.map((task, index) => (
                            <div
                              key={index?.toString()}
                              className={`relative max-w-[7rem]`}
                            >
                              <div
                                onClick={() =>
                                  editTask({
                                    target: {
                                      day,
                                      note,
                                      tasks,
                                      index: i,
                                      taskId,
                                    },
                                    task: { taskIndex: index, ...task },
                                  })
                                }
                                className="bg-indigo-500/90 text-white cursor-pointer overflow-hidden whitespace-nowrap px-1 rounded text-sm max-w-[112px] min-w-[112px] text-center truncate py-[2px]"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={task?.label}
                              >
                                {task?.label}
                              </div>
                              <div className="mt-1 w-full cursor-pointer group-odd:bg-indigo-100 group-even:bg-indigo-50 h-[calc(100%-30px)] rounded flex flex-col justify-center items-center relative">
                                <div className="absolute w-4 top-2 right-1 z-90">
                                  <GoalAction
                                    data={task}
                                    editAction={() => {
                                      editTask({
                                        target: {
                                          day,
                                          note,
                                          tasks,
                                          index: i,
                                          taskId,
                                        },
                                        task: { taskIndex: index, ...task },
                                      });
                                    }}
                                    deleteAction={(data) => {
                                      removeHandler({
                                        ...data,
                                        taskElementId: taskId,
                                        day,
                                        index: i,
                                        taskIndex: index,
                                      });
                                    }}
                                  />
                                </div>
                                {/* {console.log(task?.completed)} */}
                                <Checkbox
                                  // checked={task?.completed === 1}
                                  isChecked={task.completed == 1}
                                  onChange={(val) =>
                                    onTaskStatusChange({
                                      index: i,
                                      day,
                                      taskIndex: index,
                                      value: val == true ? 1 : 2,
                                      taskElementId: taskId,
                                      subTaskId: task?._id,
                                    })
                                  }
                                />
                                {addComplete?.status?.index === i &&
                                  addComplete?.status?.taskIndex == index &&
                                  !addComplete?.status?.complete && (
                                    <div className="absolute top-0 left-0 w-full h-full">
                                      <Dot />
                                    </div>
                                  )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    {tasks && Array?.isArray(tasks) && tasks?.length === 0 && (
                      <div className="w-full h-full group-odd:bg-indigo-100 group-even:bg-indigo-50">
                        <div className="w-full h-[90px] flex flex-col items-center justify-center">
                          <img src={"/images/no_data.svg"} alt="no-task" />
                          <div className="text-slate-300">No Data</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="flex gap-2 sticky top-0 z-30 h-16">
        <div className="w-[calc(100%-136px)] h-full flex gap-2 ml-auto">
          <div className="ml-auto h-20 w-1/2 flex gap-1 overflow-y-auto scrollbar">
            {note[0]?.tasks &&
              Array.isArray(note[0]?.tasks) &&
              note[0]?.tasks?.length > 0 &&
              note[0]?.tasks?.map((task, i) => {
                const total_complete = note
                  ?.map((item, index) => item?.tasks[i])
                  ?.filter((task) => task?.completed == 1);
                return (
                  <div key={i?.toString()} className="h-full w-fit">
                    <div className="text-sm h-1 overflow-y-hidden opacity-0 px-1">
                      {task?.name}
                    </div>
                    <div className="bg-indigo-900 text-white cursor-pointer overflow-hidden whitespace-nowrap px-1 rounded text-sm min-w-[112px] text-center py-[2px]">
                      Total Complete
                    </div>
                    <div className="rounded bg-indigo-50 text-indigo-900 mt-1 h-[calc(100%-36px)] flex flex-col items-center justify-center">
                      {total_complete?.length || 0} /{note?.length || 0}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Modal
        isOpen={ModalOpen?.isOpen}
        setIsOpen={() =>
          setModalIsOpen({ isOpen: false, target: null, editData: null })
        }
        size="md"
      >
        <AddTask
          cancel={() =>
            setModalIsOpen({ isOpen: false, target: null, editData: null })
          }
          saveHandler={(data) => onSave(data)}
          title={ModalOpen?.editData ? "Edit Goal" : "Add Goal"}
          editData={ModalOpen?.editData}
          loading={!addComplete?.task}
        />
      </Modal>
      <DeleteModal
        setIsOpen={() =>
          setItemDelete({
            deleteComplete: true,
            openModal: false,
            data: {
              apiEndpoint: "admin/delete-all-subtask",
              targetId: undefined,
              method: "post",
            },
          })
        }
        isOpen={itemDelete?.openModal}
        loading={!itemDelete?.deleteComplete}
        data={{
          ...itemDelete?.data,
          payload: {
            body: {
              ...itemDelete?.data?.payload?.body,
              userId: selectedUser?.value,
            },
          },
        }}
        customMessage="Are you sure you want to delete this goal for the whole month?"
        // data={itemDelete?.data}
        getResponse={getResponseHandlerAfterDelete}
        deleteFunction={() => {
          setItemDelete((pre) => ({
            ...pre,
            deleteComplete: false,
          }));
        }}
      />
    </>
  );
};

export default RenderdayByMonths;
