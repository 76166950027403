import React from "react";
import { tooltipData } from "../../../../utils/constant";

function DailyTask({ item }) {
  // console.log(item);
  return (
    <div className="bg-slate-50 w-full  rounded-md p-2 hover:shadow-md flex flex-col gap-2 relative  transition duration-300 ease-in-out ">
      <p className="  px-4 py-1 bg-indigo-800 text-white rounded-md  w-fit">
        {item?.subTask[0]?.name}
      </p>
      <p className="text-base text-indigo-800">{item?.name}</p>
      <div className="flex gap-4 absolute right-3 top-3">
        <div
          className=" w-14 h-6 rounded-md bg-indigo-300  p-2 "
          data-tooltip-content={"Completed Time"}
          data-tooltip-id={tooltipData.id}
        >
          <div className="flex text-indigo-900 w-full h-full items-center justify-center gap-1">
            <span>
              <i class="fa-light fa-clock text-sm"></i>
            </span>
            <span className="text-sm">{item?.subTask[0]?.completedTime}</span>
          </div>
        </div>
        <div
          className="  rounded-md bg-indigo-300  px-2 py-0.5 "
          data-tooltip-content={"Total Time"}
          data-tooltip-id={tooltipData.id}
        >
          <div className="flex text-indigo-900 wp-2 items-center justify-center gap-1">
            <span>
              <i class="fa-light fa-clock text-sm"></i>
            </span>
            <span className="text-sm">{item?.subTask[0]?.totalTime}</span>
          </div>
        </div>
      </div>
      <div
        className=" px-2 rounded-md bg-indigo-900   absolute right-3 bottom-1 cursor-pointer"
        data-tooltip-content={"Edit"}
        data-tooltip-id={tooltipData.id}
        onClick={() => console.log("Edit button clicked")}
      >
        <div className="flex text-white w-full h-full items-center justify-center gap-1"></div>
      </div>
    </div>
  );
}

export default DailyTask;
