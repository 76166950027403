// import React, { useEffect, useState } from "react";
// import "../../css/datepicker.css";
// import { classNames } from "../../helpers";
// // import { classNames } from "../../../helpers/classNames";

// const DatePicker = ({
//   time = false,
//   isDisabled = false,
//   format = "YYYY-MM-DD",
//   inputName = "",
//   value,
//   label,
//   labelClasses,
//   onChange = () => {},
// }) => {
//   const [date, setDate] = useState(null);

//   useEffect(() => {
//     if (value) {
//       setDate(value);
//     }
//   }, [value]);

//   return (
//     <>
//       {label && (
//         <div
//           className={classNames(
//             "text-sm font-medium text-slate-500 mb-1",
//             labelClasses
//           )}
//         >
//           {label}
//         </div>
//       )}
//       {isDisabled && (
//         <div className="w-full h-10 border border-slate-300 px-3 py-1 flex items-center text-sm text-indigo-900">
//           {date ? date : format}
//         </div>
//       )}
//       {!isDisabled && (
//         <div
//           className={`custom-date-picker w-full relative h-10 overflow-hidden border border-slate-300 flex flex-col`}
//         >
//           <input
//             name={inputName}
//             value={value}
//             onChange={(e) => {
//               onChange(e);
//               setDate(e.target.value);
//             }}
//             // type={"date"}
//             // type="datetime-local"
//             className="w-full h-full p-0 absolute top-0 left-0 opacity-0"
//           />
//           <div className="absolute bg-slate-50 top-0 left-0 w-full h-full flex items-center text-sm px-3 py-1 -z-10 text-indigo-900">
//             <div className="flex-grow">{date ? date : format}</div>
//           </div>
//           {date && (
//             <button
//               onClick={() => setDate(null)}
//               type="button"
//               className="flex-grow-0 w-8 h-full flex items-center justify-center my-auto ml-auto z-10"
//             >
//               <i class="fa-solid fa-circle-xmark text-gray-400 hover:text-red-500 !cursor-pointer"></i>
//             </button>
//           )}
//         </div>
//       )}
//     </>
//   );
// };

// export default DatePicker;
import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import "../../css/datepicker.css";

function DateTimePicker({
  inputName,
  label,
  onChange,
  dateFormat,
  selectedYear,
  selectedMonth,
}) {
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());

  const handleDateTimeChange = (selectedDates) => {
    //   setSelectedDateTime(selectedDates[0]);
    // console.log(selectedDates);
    onChange(selectedDates[0]);
  };

  //   const handleMonthChange = (selectedDates, dateStr, instance) => {
  //     instance.jumpToDate(selectedDateTime); // Reset back to the selected date
  //   };

  const handleYearChange = (selectedDates, dateStr, instance) => {
    instance.jumpToDate(selectedDateTime); // Reset back to the selected date
  };
  const startOfMonth = new Date(selectedYear, selectedMonth, 1);

  // Calculate the end of the month
  const endOfMonth = new Date(selectedYear, selectedMonth + 1, 0);
  return (
    <Flatpickr
      //   value={selectedDateTime}
      onChange={handleDateTimeChange}
      placeholder="Please select a date and time"
      options={{
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        // minDate: startOfMonth, // Set minDate to the selected date
        // maxDate: endOfMonth, // Set maxDate to the selected date
        // nextArrow: "",
        // prevArrow: "",
        // onMonthChange: handleMonthChange,
        // onYearChange: handleYearChange,
        monthSelectorType: "dropdown",
        time_12hr: true,

        // disable: [
        //   // Disable month and year change
        //   function (date) {
        //     return (
        //       date.getMonth() !== selectedDateTime.getMonth() ||
        //       date.getFullYear() !== selectedDateTime.getFullYear()
        //     );
        //   },
        // ],
      }}
      className="custom-flatpickr w-full"
    />
  );
}

export default DateTimePicker;
