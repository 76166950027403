const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const handleOnDragEnd = (
  result,
  list = [],
  setList = () => {},
  callback = () => {}
) => {
  if (!result.destination) return;

  const items = reorder(list, result.source.index, result.destination.index);
  setList(items);
  callback(items);
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
