import React, { useEffect, useState } from "react";
import { removeHtmlTags, withTryCatch } from "../../utils/function";
import { fetchData } from "../../helpers/fetchData";
import Table from "../elements/table/Table";

function RecentDiaryTask() {
  const [list, setList] = useState({ data: [], isLoding: false });
  const getList = withTryCatch(async () => {
    setList((prev) => ({ ...prev, isLoding: true }));
    let resp = await fetchData("get", "/admin/list-top", {
      queries: { topList: "1", type: "diary", limit: 10 },
    });
    if (resp.data.docs.status === 200) {
      setList((prev) => ({
        isLoding: false,
        data: [...resp?.data?.docs?.data],
      }));
    } else {
      setList((prev) => ({ isLoding: false, data: [] }));
    }
  });
  useEffect(() => {
    getList();
  }, []);
  const tableHeadDashbord = [
    {
      _id: crypto.randomUUID(),
      name: "Si. no.",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Task",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Day",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Month",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Type",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "year",
      align: "left",
      isSort: false,
      isFilter: false,
    },
  ];
  const tableData = Array.from({ length: 10 }).map((_, index) => {
    const item = list?.data?.[index];
    return {
      _id: item?._id || crypto.randomUUID(),
      rowData: [
        {
          _id: crypto.randomUUID(),
          type: "text",
          data: index + 1,
        },
        {
          _id: crypto.randomUUID(),
          type: "text",
          data: item ? removeHtmlTags(item?.task[0]?.name).substr(0, 50) : "",
        },
        {
          _id: crypto.randomUUID(),
          type: "text",
          data: item?.day || "",
        },
        {
          _id: crypto.randomUUID(),
          type: "text",
          data: item?.month || "",
        },
        {
          _id: crypto.randomUUID(),
          type: "text",
          data: item?.type || "",
        },
        {
          _id: crypto.randomUUID(),
          type: "text",
          data: item?.year || "",
        },
      ],
    };
  });
  return (
    <div className="bg-white p-4 rounded-md shadow-md">
      <Table
        tableData={tableData}
        tableHeadData={tableHeadDashbord}
        isLoder={list.isLoding}
        isOrderingDisabled={true}
      />
    </div>
  );
}

export default RecentDiaryTask;
