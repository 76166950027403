import React from "react";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

function DateTimePicker({ placeholder = "Select Date and Time" }) {
  return (
    <Flatpickr
      placeholder={placeholder} // corrected
      className="!w-full !outline-none" // removed extra space
      enableTime={true}
      dateFormat={"Y-m-d H:i"}
    />
  );
}

export default DateTimePicker;
