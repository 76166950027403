import React, { useEffect, useMemo, useState } from "react";
// import Table from "../../../components/elements/table/Table";
import Table from "../../../components/elements/table/Table";
import HeadingWrapper from "../../../components/common/HeadingWrapper";
import Search from "../../../components/form/Search";
import {
  exportToCSV,
  handleStatusChange,
  withTryCatch,
} from "../../../utils/function";
import { fetchData } from "../../../helpers/fetchData";
import { toast } from "react-toastify";
import Button from "../../../components/form/Button";
import { useDebounce } from "../../../components/common/useDebounce/UseDebounce";
import Select from "../../../components/form/Select";
import { roleCode } from "../../../utils/enum";
import Pagination from "../../../common/Pagination";
import { offset } from "@popperjs/core";
import DeleteModal from "../../../components/common/DeleteModal";

function Users({ isDashBoard }) {
  const [list, setList] = useState({ data: [], isLoding: true });
  const [filters, setFilters] = useState({ roleCode: "", currentPage: 0 });
  const [searchTerm, setSearchTerm] = useState("");
  const debounce = useDebounce(searchTerm, 400);

  const tableHeadDashbord = [
    {
      _id: crypto.randomUUID(),
      name: "Si. no.",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Image",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "First name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Last name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Email",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    !isDashBoard && {
      _id: crypto.randomUUID(),
      name: "Action",
      align: "left",
      isSort: false,
      isFilter: false,
    },
  ];
  const [deleteUser, setDeleteUser] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const tableData = useMemo(() => {
    return list?.data?.map((item, index) => ({
      _id: item?._id,
      rowData: [
        {
          _id: crypto.randomUUID(),
          type: "text",
          data: index + 1,
        },
        {
          _id: crypto.randomUUID(),
          type: "user",
          image: item?.image || "",
        },
        {
          _id: crypto.randomUUID(),
          type: "text",
          data: item?.firstName || "",
        },
        {
          _id: crypto.randomUUID(),
          type: "text",
          data: item?.lastName || "",
        },
        {
          _id: crypto.randomUUID(),
          type: "text",
          data: item?.email || "",
        },
        {
          _id: crypto.randomUUID(),
          type: "status",
          statusType: item?.status === "active" ? "success" : "danger",
          statusLabel: item?.status === "active" ? "Active" : "Inactive",
          isStatusChangeRequired: true,
          onClick: async () => {
            let updatedList = await handleStatusChange(
              list.data,
              item,
              index,
              "admin/user-status"
            );
            setList((prev) => ({ ...prev, data: [...updatedList] }));
          },
        },
        !isDashBoard && {
          _id: crypto.randomUUID(),
          type: "action",
          actionData: [
            {
              _id: 1,
              label: "Edit",
              icon: "fa-pen-to-square",
              link: `/users/manage/${item._id}`,
              standout: false,
            },
            {
              _id: crypto.randomUUID(),
              label: "Delete",
              icon: "fa-solid fa-trash",
              // link: `/users/manage/${item._id}`,
              standout: true,
              onClick: () => {
                setDeleteUser(item._id);
                setIsDeleteModalOpen(true);
              },
            },
            {
              _id: 2,
              label: "Go To Daily Task",
              icon: "fa-regular fa-timer",
              link: `/daily/${item._id}`,
              standout: false,
            },
            {
              _id: 3,
              label: "Go To Month Task",
              icon: "fa-solid fa-calendar-days",
              link: `/months/${item._id}`,
              standout: false,
            },
            {
              _id: 4,
              label: "Go To Diary Task",
              icon: "fa-solid fa-calendar",
              link: `/diary/${item._id}`,
              standout: false,
            },
          ],
        },
      ],
    }));
  }, [list.data, isDashBoard]);

  const getUsers = withTryCatch(async () => {
    setList((prev) => ({ ...prev, isLoding: true, data: [] }));
    let users = await fetchData("get", "admin/user-list", {
      queries: {
        keyword: debounce,
        roleCode: filters.roleCode,
        limit: 10,
        page: filters.currentPage + 1,
      },
    });

    if (users?.data?.docs?.status === 200) {
      setList((prev) => ({
        ...prev,
        isLoding: false,
        data: [...users.data?.docs?.data],
        totalDocs: users.data?.docs?.metadata?.totalDocs,
        totalPages: users.data?.docs?.metadata?.totalPages,
      }));
    } else {
      toast.warn(users?.data?.docs.message);
    }
    setList((prev) => ({
      ...prev,
      isLoding: false,
    }));
  });

  useEffect(() => {
    getUsers();
  }, [debounce, filters.roleCode, filters.currentPage]);

  let csvData = list?.data?.map((item, index) => ({
    ["Si. no."]: index + 1,
    ["First name"]: item?.firstName,
    ["Last name"]: item?.lastName,
    ["Email"]: item?.email,
    ["Status"]: item?.status || 1,
  }));

  const getResponseHandlerAfterDelete = (resp) => {
    if (resp?.data?.status === 200) {
      toast.success(resp?.data?.message);
      setIsDeleteModalOpen(false);
      setList((prev) => ({
        ...prev,
        data: prev.data.filter((item, index) => item._id !== deleteUser),
      }));
      setDeleteUser("");
    } else {
      toast.warn(resp?.data?.message);
    }
  };
  return (
    <>
      <div className="flex gap-4 justify-between items-center w-full ">
        {!isDashBoard && (
          <div>
            <HeadingWrapper title={"Users"} />
          </div>
        )}
        {!isDashBoard && (
          <div className="flex gap-4 items-center ">
            <div className="relative mb-4 w-40">
              <Select
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                label="Select Role"
                placeholder="Select Role"
                dropdownClass={""}
                dropdownData={[
                  { _id: 1, label: "Admin", value: "admin" },
                  { _id: 2, label: "User", value: "user" },
                ]}
                inputName={"roleCode"}
                onChange={(val) => {
                  setFilters((prev) => ({ roleCode: val.value }));
                  // setPaylodData((prev) => ({ ...prev, roleCode: val.value }));
                }}
                selectedValue={filters.roleCode || ""}
              />
            </div>
            <div className="relative">
              <Search
                placeholder="Search with name..."
                mainClass={"  !shadow-sm !ml-auto"}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm || ""}
              />
            </div>
            {/* <i class=""></i> */}
            <div className="relative">
              <Button
                buttonLabel={"Export"}
                buttonIcon={"fa-solid fa-download"}
                buttonIconPosition={"left"}
                buttonClasses={"!w-fitt "}
                buttonHasLink={false}
                // buttonLink={"/users/manage"}
                buttonFunction={() => {
                  if (csvData.length) {
                    exportToCSV(csvData, "users.csv");
                  }
                }}
              />
            </div>
            <div className="relative">
              <Button
                buttonLabel={"Add User"}
                buttonIcon={" fa-solid fa-plus"}
                buttonIconPosition={"left"}
                buttonClasses={"!w-fitt "}
                buttonHasLink={true}
                buttonLink={"/users/manage"}
              />
            </div>
          </div>
        )}
      </div>

      <div className="bg-white p-4 rounded-md shadow-md">
        <Table
          tableData={tableData}
          tableHeadData={tableHeadDashbord}
          isLoder={list.isLoding}
          isOrderingDisabled={true}
        />
        {!list.isLoding && list.data.length && !isDashBoard && (
          <div className="relative flex items-center justify-end mt-4">
            <Pagination
              totalPages={list.totalPages}
              onPageChange={(page) => {
                setFilters((prev) => ({ ...prev, currentPage: page }));
              }}
              currentPage={filters.currentPage}
            />
          </div>
        )}
      </div>
      <DeleteModal
        setIsOpen={() => setIsDeleteModalOpen(false)}
        isOpen={isDeleteModalOpen}
        // loading={!itemDelete?.deleteComplete}
        data={{
          payload: { body: { id: [deleteUser] } },
          apiEndpoint: "admin/user-delete",
          method: "delete",
        }}
        getResponse={getResponseHandlerAfterDelete}
        // deleteFunction={() => {
        //   setItemDelete((pre) => ({
        //     ...pre,
        //     deleteComplete: false,
        //   }));
        // }}
      />
    </>
  );
}

export default Users;
