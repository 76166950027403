import React, { useCallback, useEffect, useRef, useState } from "react";
import NotesItem from "./NotesItem";
import {
  addNoteAndTaskInMonth,
  getMonthList,
} from "../../../../services/monthService";
import moment from "moment";
import { toast } from "react-toastify";
import DeleteModal from "../../../../components/common/DeleteModal";
import { exportToCSV, withTryCatch } from "../../../../utils/function";
import Button from "../../../../components/form/Button";
import { Tooltip } from "react-tooltip";
import Modal from "../../../../common/Modal";
import Input from "../../../../components/form/Input";
import DateTimePicker from "../../../../components/common/dateTimePicker/DateTimePicker";
import { fetchData } from "../../../../helpers/fetchData";
import ProgressItem from "./ProgressItem";

const Note = ({
  title = "",
  type = "",
  selectedYear,
  selectedMonth,
  selectedUser,
  dailyResponse,
}) => {
  const scrollSec = useRef(null);
  let previousTaskHeight = 0;
  const [itemDelete, setItemDelete] = useState({
    deleteComplete: true,
    openModal: false,
    data: {
      apiEndpoint: "put",
      targetId: undefined,
      method: "post",
    },
  });
  const [complete, setComplete] = useState({
    index: null,
    isComplete: true,
  });
  const [content, setContent] = useState([
    {
      day: moment(new Date()).format("DD"),
      task: [],
    },
  ]);

  useEffect(() => {
    if (scrollSec.current) {
      function scrollBottom() {
        if (previousTaskHeight < scrollSec.current.scrollHeight) {
          scrollSec.current.scrollTop =
            scrollSec.current.scrollHeight - scrollSec.current.clientHeight;
          previousTaskHeight = scrollSec.current.scrollHeight;
        }
      }
      setTimeout(() => {
        scrollBottom();
      }, 200);
      clearTimeout();
    }
  }, [content]);

  const saveNote = (data) => {
    const new_data = {
      type: "monthly",
      subType: type,
      date: moment(data?.date).format("YYYY-MM-DD"),
      userId: selectedUser?.value,
    };

    if (data?._id) {
      new_data["name"] = data?.name;
      new_data["taskElementId"] = data?._id;
    } else {
      new_data["task[0][name]"] = data?.name;
    }

    setComplete({
      index: data?.noteIndex,
      isComplete: false,
    });

    addNoteAndTaskInMonth(new_data).then((res) => {
      if (res?.status === 201 || res?.status === 200) {
        setContent((pre) =>
          pre?.map((item) =>
            item?.day == res?.data?.day
              ? {
                  ...res?.data,
                  task: res?.data?.task,
                }
              : item
          )
        );
        toast.success(res?.message);
      }
      setComplete({
        index: null,
        isComplete: true,
      });
    });
  };

  const removeHandler = (data) => {
    setItemDelete((pre) => ({
      ...pre,
      openModal: true,
      data: {
        apiEndpoint: "admin/task-delete",
        targetId: undefined,
        method: "put",
        data: data?.data,
        payload: {
          body: {
            entryId: data?.content?._id,
            taskElementId: data?.data?._id,
          },
        },
      },
    }));
  };

  const getResponseHandlerAfterDelete = (res) => {
    if (res?.data?.status == 200) {
      toast.success(res?.data?.message);
      setContent((pre) =>
        pre?.map((item, i) =>
          i == res?.data?.data?.index
            ? {
                ...item,
                task:
                  item?.task?.length > 1
                    ? item?.task?.filter(
                        (task, index) => index != res?.data?.data?.taskIndex
                      )
                    : [],
              }
            : item
        )
      );
    }
    setItemDelete({
      deleteComplete: true,
      openModal: false,
      data: {
        apiEndpoint: "admin/task-delete",
        targetId: undefined,
        method: "post",
      },
    });
  };
  const [data, setData] = useState([]);

  const csvData = content?.map((item, index) => ({
    date: moment(item?.dateObject).format("YYYY-MM-DD"),
    task: item?.task?.map((item) => item?.name).join(" , "),
    ["Goal Type"]: item?.goalType,
  }));

  // console.log(data);
  const getTooltipContent = (type) => {
    if (type === "monthly") {
      return "Add Goal";
    } else if (type === "event") {
      return "Add Event";
    } else if (type === "birthday") {
      return "Add Event";
    }
  };

  const getData = withTryCatch(async () => {
    let resp = await fetchData("get", "admin/view-progress", {
      queries: {
        year: selectedYear,
        month: selectedMonth + 1,
        type: type,
        subType: "daily",
        userId: selectedUser?.value,
      },
    });
    if (resp?.data?.status === 200) {
      setData(resp?.data?.data);
    } else {
      toast.warn(resp?.data?.message);
    }
  });
  useEffect(() => {
    if (selectedUser?.value) {
      getData();
    }
  }, [selectedUser?.value, selectedYear, selectedMonth, type]);
  useEffect(() => {
    setData(dailyResponse);
  }, [dailyResponse]);

  return (
    <>
      <div className="border border-indigo-900 rounded-md overflow-hidden shadow-md">
        <div className="h-9 text-center flex items-center justify-between bg-indigo-900 px-4 text-white font-semibold">
          <span>{title}</span>
          {/* <div className="flex items-center justify-center gap-4">
            <span
              className=" cursor-pointer"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Export CSV"
              onClick={() => {
                if (csvData.length) {
                  exportToCSV(
                    csvData,
                    title?.split(" ")?.join("_")?.toLowerCase()
                  );
                }
              }}
            >
              <i className="fa-solid fa-download" />
            </span>
          </div> */}
        </div>
        <div
          ref={scrollSec}
          className="`min-h-[100px] p-3 px-4 flex flex-col  gap-y-1
           max-h-96 overflow-y-auto scrollbar"
        >
          <div className="flex gap-2 flex-wrap">
            {data?.length > 0 &&
              data?.map((item, index) => (
                <ProgressItem
                  key={index?.toString()}
                  title={item?.name}
                  completeTime={item?.completedTime}
                  totalTime={item?.totalTime}
                />
              ))}
          </div>

          {content &&
            Array.isArray(content) &&
            content?.map((item) => item?.task).flat(Infinity)?.length == 0 && (
              <div className="w-full h-[90px] flex flex-col items-center justify-center">
                <img src={"/images/no_data.svg"} alt="no-task" />
                <div className="text-slate-300">No Data</div>
              </div>
            )}
        </div>
      </div>
      <DeleteModal
        setIsOpen={() =>
          setItemDelete({
            deleteComplete: true,
            openModal: false,
            data: {
              apiEndpoint: "admin/task-delete",
              targetId: undefined,
              method: "post",
            },
          })
        }
        isOpen={itemDelete?.openModal}
        loading={!itemDelete?.deleteComplete}
        data={itemDelete?.data}
        getResponse={getResponseHandlerAfterDelete}
        deleteFunction={() => {
          setItemDelete((pre) => ({
            ...pre,
            deleteComplete: false,
          }));
        }}
      />
    </>
  );
};

export default React.memo(Note);
