import React, { useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";
import Input from "../../../../components/form/Input";
import Button from "../../../../components/form/Button";
import { getOrdinalSuffix, withTryCatch } from "../../../../utils/function";
import DeleteModal from "../../../../components/common/DeleteModal";
import HideableInput from "../../../../components/common/hiddableInput/HiddableInput";
import { calcLength } from "framer-motion";
import Modal from "../../../../common/Modal";
import Select from "../../../../components/form/Select";
import { fetchData } from "../../../../helpers/fetchData";
import DailyTask from "./DailyTask";
import moment from "moment";
import { formData } from "../../../../utils/constant";

const DailyTraking = ({
  monthIndex,
  selectedYear,
  selectedMonth,
  days = [],
  saveComplete = { index: null, targetDay: null, isComplete: true },
  setdays = () => {},
  onSave = () => {},
  selectedUser,
  setDailyResponse = () => {},
}) => {
  const [itemDelete, setItemDelete] = useState({
    deleteComplete: true,
    openModal: false,
    data: {
      apiEndpoint: "admin/task-delete",
      targetId: undefined,
      method: "post",
      data: null,
      payload: {
        body: {
          ids: null,
        },
      },
    },
  });

  const getCurrentMonth = (monthIndex, day) => {
    const year = selectedYear;
    const date = new Date(year, monthIndex, day);

    // Get the day of the week (e.g., Monday)
    const dayOfWeek = new Intl.DateTimeFormat("en-US", {
      weekday: "long",
    }).format(date);

    // Get the month name from the Date object
    const monthName = new Intl.DateTimeFormat("en-US", {
      month: "short",
    }).format(date);

    // Add suffixes to the day
    const dayWithSuffix =
      day + (day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th");
    // return `${dayWithSuffix} ${monthName}, ${dayOfWeek}`;
    return `${dayWithSuffix}`;
  };

  const getCurrentDate = (monthIndex, day) => {
    const year = selectedYear;
    const currentMonth = monthIndex;
    const currentDate = new Date(year, currentMonth, day).getDay();
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][currentDate];
  };

  const mon = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const addNote = ({ day, index }) => {
    setdays(
      days?.map((elm, i) =>
        elm?.day === day
          ? {
              ...elm,
              notes: [...elm?.notes, { value: "" }],
            }
          : elm
      )
    );
  };

  const removeNote = ({ day, note_index, note, daysData }) => {
    if (note?._id) {
      setItemDelete((pre) => ({
        ...pre,
        openModal: true,
        data: {
          apiEndpoint: "admin/task-delete",
          targetId: undefined,
          method: "put",
          data: { day, note_index, note, daysData },
          payload: {
            body: {
              entryId: daysData?._id,
              taskElementId: note?._id,
            },
          },
        },
      }));
    } else {
      let updatedDays = [...days];
      updatedDays[day - 1].notes.splice(note_index, 1);
      setdays(updatedDays);
    }
  };

  const onChangeNoteValue = ({ value, day, note_index }) => {
    setdays((pre) => {
      pre[day - 1].notes[note_index].value = value;
      return [...pre];
    });
  };

  const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysCount = daysInMonth(selectedYear, monthIndex);

  useEffect(() => {
    if (daysCount) {
      setdays(
        Array.from({ length: daysCount }, (_, i) => i + 1)?.map((day, i) => ({
          _id: i?.toString(),
          day,
          // notes: [{ value: "" }],
          notes: [],
        }))
      );
    }
  }, [daysCount, selectedUser]);

  const getResponseHandlerAfterDelete = (res) => {
    setItemDelete({
      deleteComplete: true,
      openModal: false,
      data: {
        apiEndpoint: "put",
        targetId: undefined,
        method: "post",
      },
    });
    if (res?.data?.status === 200) {
      setdays(
        days?.map((elm, i) =>
          elm?.day === res?.data?.data?.day
            ? {
                ...elm,
                notes: elm?.notes?.filter(
                  (_, i) => i !== res?.data?.data?.note_index
                ),
              }
            : elm
        )
      );
      toast?.success(res?.data?.message);
    } else {
      toast?.error(res?.data?.message);
    }
  };
  const presentDayRef = useRef(null);
  const [selectedDateIndex, setSelecteddateIndex] = useState(
    new Date().getDate() - 1
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paylodData, setPaylodData] = useState({
    name: "",
    completedTime: "",
    identicalId: "",
    type: "monthly",
    subType: "daily",
  });
  const [isBtnDissable, setIsBtnDissable] = useState(false);
  const [taskDropDown, setTaskDropDown] = useState([]);
  const getDetails = async () => {
    let resp = await fetchData("get", "/admin/dailygoal-list", {
      queries: {
        userId: selectedUser?.value,
        type: "daily",
        subType: "daily",
        year: selectedYear,
        month: selectedMonth + 1,
        goalType: "quantifiable",
      },
    });
    if (resp?.data?.status === 200) {
      setTaskDropDown(
        resp.data.data.map((item, index) => ({
          label: item?.name,
          value: item?.identicalId,
        }))
      );
    }
  };

  useEffect(() => {
    if (selectedUser?.value) {
      getDetails();
    }
  }, [selectedUser?.value]);

  const [selectdDay, setSelectedDay] = useState(1);

  const saveTask = withTryCatch(async () => {
    setIsBtnDissable(true);
    if (
      !paylodData.name ||
      !paylodData.identicalId ||
      !paylodData.completedTime
    ) {
      toast.warn("All fields are required");
      setIsBtnDissable(false);
      return;
    }
    let date = new Date();
    date.setFullYear(selectedYear);
    date.setMonth(selectedMonth);
    date.setDate(selectdDay);
    let resp = await fetchData(
      "post",
      "admin/add",
      {
        body: {
          ...paylodData,
          date: moment(date).format("YYYY-MM-DD"),
          userId: selectedUser?.value,
        },
      },
      formData
    );
    if (resp?.data?.status === 200 || resp?.data?.status === 201) {
      // console.log();
      setDailyResponse(resp.data.monthlyProgress);
      toast.success(resp?.data?.message);
      setIsModalOpen(false);

      let updatedDays = [...days];
      if (updatedDays[selectdDay - 1]?.notes) {
        updatedDays[selectdDay - 1].notes = [...resp?.data?.data?.task];
      }
      setdays(updatedDays);

      setPaylodData({
        name: "",
        completedTime: "",
        identicalId: "",
        type: "monthly",
        subType: "daily",
      });
    } else {
      setIsModalOpen(false);
      toast.warn(resp.data.message);
    }
    setIsBtnDissable(false);
  });
  return (
    <>
      {days.map(({ day, notes, _id }, index) => (
        <div
          className="group bg-indigo-100 px-2 rounded-lg py-2 mb-3 "
          key={_id}
          id={`day-${index}`}
        >
          <div
            className={`flex items-center space-x-1 mb-3 space-y-0.5 day  ${
              getCurrentDate(monthIndex, day) === "Saturday" ||
              getCurrentDate(monthIndex, day) === "Sunday"
                ? "weekend"
                : ""
            }`}
          >
            <div className="day-label text-lg text-indigo-900 font-bold tracking-wider">
              <span>{getCurrentMonth(monthIndex, day)}</span>
            </div>
            <Button
              buttonLabel={"Add"}
              buttonIconPosition={"left"}
              buttonIcon={"fa-light fa-plus"}
              buttonLabelClasses={"capitalize"}
              buttonClasses={"!ml-auto"}
              // buttonFunction={() => addNote({ day, index })}
              buttonFunction={() => {
                setIsModalOpen(true);
                setSelectedDay(day);
              }}
              toolTipLabel="Add"
            />
          </div>
          <div className="flex flex-col gap-2">
            {notes.map((item, index) => (
              <React.Fragment>
                <DailyTask item={item} />
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
      <DeleteModal
        setIsOpen={() =>
          setItemDelete({
            deleteComplete: true,
            openModal: false,
            data: {
              apiEndpoint: "put",
              targetId: undefined,
              method: "post",
            },
          })
        }
        isOpen={itemDelete?.openModal}
        loading={!itemDelete?.deleteComplete}
        data={itemDelete?.data}
        getResponse={getResponseHandlerAfterDelete}
        deleteFunction={() => {
          setItemDelete((pre) => ({
            ...pre,
            deleteComplete: false,
          }));
        }}
      />
      <Modal
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        modalClass="!w-1/3"
        onSucess={() => {
          saveTask();
        }}
        isDisabled={isBtnDissable}
      >
        <div className="flex flex-col gap-4">
          <div className="relative">
            <Select
              label={"Select Goal"}
              placeholder={"Please Select Goal"}
              dropdownData={[...taskDropDown]}
              selectedValue={paylodData?.identicalId}
              onChange={(val) =>
                setPaylodData((prev) => ({ ...prev, identicalId: val.value }))
              }
            />
          </div>
          <div className="relative">
            <Input
              label={"Remark"}
              inputPlaceholder={"Please Enter Remark"}
              value={paylodData.name}
              onChange={(e) =>
                setPaylodData((prev) => ({ ...prev, name: e?.target?.value }))
              }
            />
          </div>
          <div className="relative">
            <Input
              label={"Target"}
              inputPlaceholder={"Please Enter Completed Target"}
              inputType={"number"}
              onChange={(e) => {
                setPaylodData((prev) => ({
                  ...prev,
                  completedTime: e?.target?.value,
                }));
              }}
              value={paylodData.completedTime}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DailyTraking;
