import React, { useState } from "react";
// import DatePicker from "../form/Datpicker";
import Input from "./form/Input";
import Button from "./form/Button";
import DatePicker from "./form/DatePicker";
// import { formData } from "../utils/constant";
// import Input from "../form/Input";
// import Button from "../form/Button";

const EventAndBirthdayModal = ({
  onSave = () => {},
  onClose = () => {},
  data = {},
  loading = false,
  setFormData,
  selectedMonth,
  selectedYear,
  formData,
}) => {
  const [openDatePicker, setOpenDatepicker] = useState(false);

  function onChangeHandler(e) {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((pre) => ({ ...pre, [name]: value }));
  }
  // console.log(formData);
  return (
    <div>
      <div className="flex flex-col gap-4">
        <div>
          <DatePicker
            inputName="date"
            label={"Date And Time"}
            // onChange={onChangeHandler}
            dateFormat={"Y-m-d H:i"}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            onChange={(value) =>
              setFormData((prev) => ({ ...prev, date: value }))
            }
          />
        </div>
        <div>
          <Input
            label={"Title"}
            inputName={"name"}
            inputType={"string"}
            value={formData?.name || ""}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                [e?.target?.name]: e?.target?.value,
              }))
            }
            onEnterClicked={() => onSave()}
            inputPlaceholder={"Enter title"}
          />
        </div>
      </div>
      <div
        className={`flex items-center justify-end gap-2 ${
          openDatePicker ? "mt-auto" : "mt-5"
        }`}
      >
        {/* <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close"}
          buttonLabel={"Cancel"}
          buttonFunction={onClose}
        />
        <Button
          buttonIconPosition={"left"}
          buttonIcon={
            loading
              ? "fa-duotone fa-spinner-third animate-spin"
              : "fa-regular fa-save"
          }
          buttonLabel={"Add Event"}
          buttonFunction={() => onSave(formData)}
        /> */}
      </div>
    </div>
  );
};

export default EventAndBirthdayModal;
