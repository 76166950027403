import React, { useEffect, useRef, useState } from "react";
// import Textarea from "../../form/Textarea";
// import { removeTag } from "../../../helpers";
import Textarea from "../../../../components/form/Textarea";
import { removeTag } from "../../../../utils/function";

const NoteInput = ({
  onChange = () => {},
  onSave = () => {},
  data = {},
  taskIndex,
  index,
  selectedUser,
  saveEmpty,
}) => {
  const editorRef = useRef(null);
  const [showEditor, setShowEditor] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(data?.name);
    if (!data?.name || data?.name == "") {
      setShowEditor(true);
    }
  }, [data]);

  useEffect(() => {
    if (removeTag(value) || removeTag(data?.name)) {
      setShowEditor(false);
    } else {
      setShowEditor(true);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        if (removeTag(value)) {
          setShowEditor(false);
        }
        if (!saveEmpty) {
          if (!value && data?.name) {
            setValue(data?.name);
            setShowEditor(false);
          }
        }
        if (data?.name != value) {
          onSave({ ...data, name: value, index, taskIndex });
          data[`name`] = value;
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [value]);

  const handleEditorClick = () => {
    if (!showEditor) {
      setShowEditor(true);
    }
  };
  function emptySubmit() {
    if (saveEmpty) {
      if (data?.name) {
        onSave({ ...data, name: "", index, taskIndex });
        data[`name`] = "";
        setValue("");
      }
    }
  }
  return (
    <div ref={editorRef} className="w-full h-full">
      {!showEditor ? (
        <div className="h-full" onClick={() => handleEditorClick()}>
          {value}
        </div>
      ) : (
        <>
          <Textarea
            autoFocus={value ? true : false}
            inputPlaceholder={!value ? "Add notes" : ""}
            inputValue={value}
            inputClasses="!h-12 !border-none bg-transparent pr-10"
            // onChange={(e) => {
            //   onChange(e.target.value);
            //   setValue(e.target.value);
            // }}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
              if (!e.target.value) {
                if (saveEmpty) {
                  emptySubmit();
                }
              }
            }}
          />
        </>
      )}
    </div>
  );
};

export default NoteInput;
