import React, { useEffect, useState } from "react";

const MenuTabs = ({
  menu = [],
  className = "",
  setActive = () => {},
  active,
}) => {
  //   const [active, setActive] = useState(menu?.indexOf(menu[0]) || 0);

  return (
    <div className={` ${className}`}>
      <div className="bg-gray-200 rounded-md p-1">
        <ul className="flex">
          {Array.isArray(menu) &&
            menu?.length > 0 &&
            menu?.map((item, i) => (
              <li
                key={item?._id || i.toString()}
                className={`px-4 py-2 text-gray-700 rounded-md focus:outline-none cursor-pointer ${
                  active == i ? "bg-indigo-800 !text-white" : ""
                }`}
                onClick={() => {
                  setActive(i);
                  //   setActiveTab(i);
                }}
              >
                {item?.label}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default MenuTabs;
