import React, { useEffect, useState } from "react";
import Tbody from "./Tbody";
import Thead from "./Thead";
import Loader from "../../../common/Loder";
import NoDataFound from "../../../common/NoDataFound";
import { DragDropContext } from "react-beautiful-dnd";
import { handleOnDragEnd } from "../../../helpers";
import { reorder } from "../../../utils/function";
import { toast } from "react-toastify";
import { fetchData } from "../../../helpers/fetchData";

const Table = ({
  tableData,
  tableHeadData,
  isLoder,
  isOrderingDisabled,
  originalData = [],
  handleOrdering = () => {},
  setOriginalData = () => {},
  isCheckbox = false,
  selectedItems = [],
  setSelectedItems = () => {},
  piyushOrderingEnable = false,
  orderingEndPoints = "",
  orderingMethods = "patch",
}) => {
  let onDragEnd = "";
  const [data, setData] = useState([...tableData]);
  useEffect(() => {
    setData([...tableData]);
  }, [tableData]);

  if (piyushOrderingEnable) {
    onDragEnd = async (result) => {
      let sourceIndex = result?.source?.index;
      let destinationIndex = result?.destination?.index;
      if (!(sourceIndex + 1) || !(destinationIndex + 1)) {
        toast.warn("Please drag in the selected area only");
        return;
      }
      let reorderData = reorder(data, sourceIndex, destinationIndex);
      await onDrag(reorderData, sourceIndex, destinationIndex);
    };
  } else {
    onDragEnd = (result) => {
      handleOnDragEnd(result, originalData, setOriginalData, handleOrdering);
    };
  }

  const selectAllCheckBox = () => {
    if (selectedItems.length === tableData.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(tableData.map((item, index) => item._id));
    }
  };

  const heandleCheckboxSelection = (item) => {
    setSelectedItems((items) =>
      items.includes(item._id)
        ? items.filter((filterItem) => filterItem !== item._id)
        : [...items, item._id]
    );
  };

  if (isCheckbox) {
    if (!(tableHeadData[0].type === "checkbox")) {
      tableHeadData.unshift({
        _id: crypto.randomUUID(),
        type: "checkbox",
        name: "",
        align: "left",
        isSort: false,
        isFilter: false,
        thClasses: "w-[1%] !bg-black/5",
        checked: selectedItems.length
          ? selectedItems.length === tableData.length
          : false,
        onCheckboxChange: selectAllCheckBox,
      });
    }
    tableData?.forEach((element) => {
      const rowData = element.rowData;
      if (rowData[0].type !== "checkbox") {
        let obj = {
          _id: crypto.randomUUID(),
          type: "checkbox",
          align: "left",
          data: "X",
          tdClasses: "w-[1%]",
          itemDetails: element,
          checked: selectedItems.includes(element._id),
          onCheckboxChange: heandleCheckboxSelection,
        };
        rowData.unshift(obj);
      }
    });
  }
  const onDrag = (data = [], startIndex, endIndex) => {
    let arr = [];
    let endIndexArr = data.filter((item, index) => index === endIndex);
    let startIndexArr = data.filter((item, index) => index === startIndex);
    let endObj = { id: endIndexArr[0]?._id, ordering: endIndex };
    let startObj = { id: startIndexArr[0]?._id, ordering: startIndex };
    arr = [endObj, startObj];
    setData([...data]);
    handleOnDrag(arr);
  };
  const handleOnDrag = async (arr) => {
    try {
      let resp = await fetchData(orderingMethods, orderingEndPoints, {
        body: { data: arr },
      });
      if (resp.data.status === 200) {
        toast.success(resp.data.message);
      } else {
        toast.warn(resp.data.message);
      }
    } catch (error) {
      toast.error(error.message || "Somthing went wrong in ordering");
    }
  };

  return (
    <>
      <div className="w-full overflow-auto scroll-smooth scrollbar">
        <DragDropContext onDragEnd={onDragEnd}>
          <table className="min-w-max w-full table-auto">
            <Thead tableHeadData={tableHeadData} />
            <Tbody
              tableData={piyushOrderingEnable ? data : tableData}
              isOrdering={isOrderingDisabled}
            />
          </table>
          {isLoder && (
            <div className="w-full flex items-center justify-center">
              <Loader />
            </div>
          )}
          {!isLoder && tableData?.length === 0 && <NoDataFound />}
        </DragDropContext>
      </div>
    </>
  );
};

export default Table;
