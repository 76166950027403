import React, { useEffect, useState } from "react";
import Input from "../form/Input";
import Button from "../form/Button";
import DateTimePicker from "../common/dateTimePicker/DateTimePicker";

const AddTask = ({
  cancel = () => {},
  saveHandler = () => {},
  editData = {},
  title = "Add Goal",
  loading = false,
}) => {
  const [payloadData, setPayloadData] = useState({
    goal_type: "",
    targetPercent: "",
    taskName: "",
  });

  useEffect(() => {
    if (editData) {
      setPayloadData((prev) => ({
        ...prev,
        taskName: editData?.label,
        goal_type: editData?.goalType,
        targetPercent: editData?.totalTime,
      }));
    }
  }, [editData]);

  return (
    <div className="p-4">
      <div className="text-indigo-900 text-xl font-semibold text-center border-b border-indigo-900 pb-3">
        {title}
      </div>
      <div className="my-5 flex  justify-evenly items-center">
        <div className="flex items-center gap-6 ">
          <label
            for="checked-checkbox"
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-800"
          >
            Quantifiable
          </label>
          <input
            checked={payloadData.goal_type === "quantifiable" ? true : false}
            name="type"
            id="checked-checkbox"
            type="radio"
            value={"quantifiable"}
            onChange={(e) =>
              setPayloadData((prev) => ({
                ...prev,
                goal_type: e?.target?.value,
              }))
            }
            className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out "
          />
        </div>
        <div className="flex items-center gap-6 ">
          <label
            for="checked-checkbox1"
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-800"
          >
            Unquantifiable
          </label>
          <input
            checked={payloadData.goal_type === "unquantifiable" ? true : false}
            id="checked-checkbox1"
            name="type"
            type="radio"
            value={"unquantifiable"}
            onChange={(e) =>
              setPayloadData((prev) => ({
                ...prev,
                goal_type: e?.target?.value,
              }))
            }
            className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out "
          />
        </div>
      </div>
      {payloadData?.goal_type === "quantifiable" && (
        <div className="my-5">
          <Input
            label={"Target"}
            value={payloadData?.targetPercent}
            inputType={"number"}
            onChange={(e) =>
              setPayloadData((prev) => ({
                ...prev,
                targetPercent: e?.target?.value,
              }))
            }
            inputClasses={"rounded"}
            inputPlaceholder={"Set a target"}
            onEnterClicked={() => saveHandler(payloadData)}
          />
        </div>
      )}
      <div className="my-5">
        <Input
          value={payloadData?.taskName || ""}
          onChange={(e) =>
            setPayloadData((prev) => ({ ...prev, taskName: e.target.value }))
          }
          inputClasses={"rounded"}
          inputPlaceholder={"Enter Goal"}
          label={"Goal Name"}
          onEnterClicked={() => saveHandler(payloadData)}
        />
      </div>
      <div className="flex justify-end mt-3 gap-2">
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close"}
          buttonLabel={"Cancel"}
          buttonFunction={cancel}
        />
        <Button
          disabled={loading}
          buttonIconPosition={"left"}
          buttonIcon={`${
            !loading
              ? "fa-regular fa-save"
              : "fa-duotone fa-spinner-third animate-spin transition-all"
          }`}
          buttonLabel={"Save Goal"}
          buttonFunction={() => saveHandler(payloadData)}
        />
      </div>
    </div>
  );
};

export default AddTask;
