import React, { useEffect, useState } from "react";
import HeadingWrapper from "../../../../components/common/HeadingWrapper";
import Input from "../../../../components/form/Input";
import Textarea from "../../../../components/form/Textarea";
import Button from "../../../../components/form/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { withTryCatch } from "../../../../utils/function";
import { fetchData } from "../../../../helpers/fetchData";
import { toast } from "react-toastify";
import Select from "../../../../components/form/Select";
import { useParams } from "react-router-dom";
function PlansManage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [paylodData, setPaylodData] = useState({});
  const [isDissable, setIsDissable] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);
  const [features, setFeatures] = useState({ features: [], isLoading: false });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // console.log(queryParams);
  // let id = queryParams.get("id");
  console.log(id);
  const getFeatures = withTryCatch(async () => {
    setFeatures((prev) => ({ isLoading: true, features: [] }));
    let resp = await fetchData("get", "planfeature");
    if (resp?.data?.docs?.status === 200) {
      setFeatures((prev) => ({ ...prev, features: resp?.data?.docs?.data }));
    } else {
      toast.warn(resp?.data?.message || "Something went wrong!");
    }
  });
  const getDetails = withTryCatch(async () => {
    let resp = await fetchData("get", `plan/${id}`);
    console.log(resp?.data?.data);
    if (resp?.data?.status === 200) {
      setPaylodData((prev) => ({
        name: resp?.data?.data?.name,
        price: resp?.data?.data?.price,
        description: resp?.data?.data?.description,
        interval: resp?.data?.data?.interval,
        duration: resp?.data?.data?.duration,
        intervalCount: resp?.data?.data?.intervalCount,
        features: resp?.data?.data?.featureDetail.map(
          (item, index) => item._id
        ),
      }));
    }
  });
  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, [id]);
  useEffect(() => {
    getFeatures();
  }, []);

  const handleSubmit = withTryCatch(async () => {
    setIsDissable(true);
    if (!paylodData.name || !paylodData.price || !paylodData?.duration) {
      toast.warn("All fields are required");
      setHasWarning(true);
      setIsDissable(false);
      return;
    }
    let obj = {
      method: id ? "put" : "post",
      url: !id ? "plan/add" : `plan/edit`,
    };
    let updatedPaylod = { ...paylodData };
    if (id) {
      updatedPaylod.id = id;
    }
    let resp = await fetchData(obj.method, obj.url, {
      body: { ...updatedPaylod },
    });
    if (resp?.data?.status === 200 || resp?.data?.status === 201) {
      toast.success(resp?.data?.message);
      setPaylodData({});
      navigate("/plans");
    } else {
      toast.warn(resp?.data?.message);
    }
    setIsDissable(false);
  });

  return (
    <>
      <div className="relative  flex justify-between">
        <HeadingWrapper title={"Plans Manage"} />
      </div>
      <div className="bg-white px-10 py-4 rounded-md">
        <div className="grid grid-cols-2 gap-4 ">
          <div className="relative">
            <Input
              label={"Plan Name"}
              inputPlaceholder={"Enter Plan Name"}
              inputName={"firstName"}
              inputType={"text"}
              onChange={(e) =>
                setPaylodData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              value={paylodData?.name || ""}
              isRequired={true}
            />
            {hasWarning && !paylodData?.name && (
              <span className="text-xs text-red-500">**Name is required</span>
            )}
          </div>
          <div className="relative">
            <Input
              label={"Price"}
              inputPlaceholder={"Enter Plan Price"}
              inputName={"price"}
              inputType={"text"}
              isRequired={true}
              onChange={(e) =>
                setPaylodData((prev) => ({
                  ...prev,
                  price: e.target.value,
                }))
              }
              value={paylodData?.price || ""}
            />
            {hasWarning && !paylodData?.price && (
              <span className="text-xs text-red-500">**Price is required</span>
            )}
          </div>
          <div className="relative ">
            <Select
              xPlacement={"left"}
              dropdownButtonClass={"!bg-white"}
              label="Select Type *"
              placeholder="Select Type"
              dropdownClass={""}
              dropdownData={[
                { _id: 1, label: "Day", value: "day" },
                { _id: 2, label: "Week", value: "week" },
                { _id: 2, label: "Month", value: "month" },
                { _id: 2, label: "Year", value: "year" },
              ]}
              inputName={"roleCode"}
              onChange={(val) => {
                // setFilters((prev) => ({ roleCode: val.value }));
                setPaylodData((prev) => ({ ...prev, interval: val.value }));
              }}
              selectedValue={paylodData?.interval || ""}
            />
            {hasWarning && !paylodData?.interval && (
              <span className="text-xs text-red-500">**Type is required</span>
            )}
          </div>
          <div className="relative">
            <Input
              label={"Duration"}
              inputPlaceholder={"Enter Plan Duration"}
              inputName={"duration"}
              inputType={"text"}
              onChange={(e) =>
                setPaylodData((prev) => ({
                  ...prev,
                  duration: e.target.value,
                }))
              }
              value={paylodData?.duration || ""}
              isRequired={true}
            />
            {hasWarning && !paylodData?.price && (
              <span className="text-xs text-red-500">
                **Duration is required
              </span>
            )}
          </div>

          <div className="relative">
            <Input
              label={"Interval Count"}
              inputPlaceholder={"Enter Plan Interval Count"}
              inputName={"intervalCount"}
              inputType={"text"}
              onChange={(e) =>
                setPaylodData((prev) => ({
                  ...prev,
                  intervalCount: e.target.value,
                }))
              }
              value={paylodData?.intervalCount || ""}
            />
          </div>
          <div className="relative">
            <Textarea
              inputClasses={"!h-20 !w-full"}
              label={"Description"}
              inputPlaceholder={"Enter description"}
              value={paylodData?.description || ""}
              onChange={(e) =>
                setPaylodData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <div className="features flex items-center flex-wrap gap-4 mt-6">
          {features?.features?.map((item, index) => (
            <div className="flex gap-2" key={index.toString()}>
              <span>{item?.name}</span>
              <span>
                <input
                  type="checkbox"
                  name={item._id}
                  checked={paylodData?.features?.includes(item?._id)}
                  onChange={(e) => {
                    setPaylodData((prev) => ({
                      ...prev,
                      features: prev?.features?.includes(item._id)
                        ? prev?.features?.filter(
                            (prevItem) => prevItem !== item._id
                          )
                        : [...(prev?.features || []), item._id], // Add item to array if not already present
                    }));
                  }}
                />
              </span>
            </div>
          ))}
        </div>
        <div className="relative w-32 mt-4 ml-auto">
          <Button
            buttonLabel={"Add Plans"}
            buttonIconPosition={"left"}
            buttonIcon={"fa-light fa-plus"}
            buttonLabelClasses={"capitalize"}
            buttonClasses={"!ml-auto !w-full"}
            buttonFunction={() => handleSubmit()}
            toolTipLabel="Add"
            buttonDisabled={isDissable}
          />
        </div>
      </div>
    </>
  );
}

export default PlansManage;
