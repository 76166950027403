import React, { useEffect, useState } from "react";

const CustomImage = ({
  src: image,
  alt,
  width,
  height,
  name,
  email,
  className = "",
  imageLayout,
  divClass,
  showFormattedName = true,
  sizes = "100vw",
  ...props
}) => {
  const [isImage, setIsImage] = useState(true);
  const [fallbackImage, setFallbackImage] = useState(null);
  useEffect(() => {
    setIsImage(true);
  }, [image]);

  const imageSizeProps =
    typeof width === "number" && typeof height === "number"
      ? {
          width,
          height,
        }
      : { sizes: "100vw" };

  return (
    <>
      {isImage &&
      image &&
      (typeof image === "string" || Object.keys(image).length > 0) ? (
        <img
          className={className}
          src={image ? image : fallbackImage}
          loading="lazy"
          onError={() => {
            setIsImage(false);
            setFallbackImage("/images/noImage.jpg");
          }}
          alt="not available"
          layout={imageLayout}
          {...imageSizeProps}
        />
      ) : (
        <img
          src={"/images/noImage.jpg"}
          loading="lazy"
          alt={alt}
          className={className}
          layout={imageLayout}
          sizes={sizes}
        />
      )}
    </>
  );
};

export default CustomImage;
