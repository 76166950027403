import React, { useEffect, useState } from "react";
import HeadingWrapper from "../../../components/common/HeadingWrapper";
import Button from "../../../components/form/Button";
import { useNavigate } from "react-router-dom";
import { withTryCatch } from "../../../utils/function";
import { fetchData } from "../../../helpers/fetchData";
import { toast } from "react-toastify";
import Table from "../../../components/elements/table/Table";

function Plans() {
  const navigate = useNavigate();
  const [list, setList] = useState({ isLoding: false, data: [] });
  const getList = withTryCatch(async () => {
    setList((prev) => ({ ...prev, data: [], isLoding: true }));
    let resp = await fetchData("get", "plan");
    if (resp?.data?.docs?.status === 200) {
      setList((prev) => ({
        ...prev,
        data: resp?.data?.docs?.data,
        isLoding: false,
      }));
    } else {
      toast.warn(resp?.data?.message || "Something went wrong");
    }
  });
  useEffect(() => {
    getList();
  }, []);
  const tableHead = [
    {
      _id: crypto.randomUUID(),
      name: "Si. no.",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: " Name",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "price",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "duration",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "interval",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "intervalCount",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "isPopular",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Status",
      align: "center",
      isSort: false,
      isFilter: false,
    },
    {
      _id: crypto.randomUUID(),
      name: "Action",
      align: "left",
      isSort: false,
      isFilter: false,
    },
  ];

  const tableData = list?.data?.map((item, index) => ({
    _id: item?._id,
    rowData: [
      {
        _id: crypto.randomUUID(),
        type: "text",
        data: index + 1,
      },
      {
        _id: crypto.randomUUID(),
        type: "text",
        data: item?.name,
      },
      {
        _id: crypto.randomUUID(),
        type: "text",
        data: item?.price,
      },
      {
        _id: crypto.randomUUID(),
        type: "text",
        data: item?.duration,
      },
      {
        _id: crypto.randomUUID(),
        type: "text",
        data: item?.interval,
      },
      {
        _id: crypto.randomUUID(),
        type: "text",
        data: item?.intervalCount,
      },
      {
        _id: crypto.randomUUID(),
        type: "text",
        data: item?.isPopular ? "yes" : "no",
      },
      {
        _id: crypto.randomUUID(),
        type: "status",
        statusType: item?.status === "active" ? "success" : "danger",
        statusLabel: item?.status === "active" ? "Active" : "Inactive",
      },
      {
        _id: crypto.randomUUID(),
        type: "action",
        actionData: [
          {
            _id: 1,
            label: "Edit",
            icon: "fa-pen-to-square",
            link: `/plans/manage/${item._id}`,
            standout: false,
          },
        ],
      },
    ],
  }));
  return (
    <>
      <div className="relative  flex justify-between">
        <HeadingWrapper title={"Plans"} />
        <div className="relative w-26">
          <Button
            buttonLabel={"Add Plans"}
            buttonIconPosition={"left"}
            buttonIcon={"fa-light fa-plus"}
            buttonLabelClasses={"capitalize"}
            buttonClasses={"!ml-auto !w-full"}
            buttonFunction={() => navigate(`/plans/manage`)}
            toolTipLabel="Add"
          />
        </div>
      </div>

      <div className=" bg-white rounded-md px-4 py-4">
        <Table
          tableHeadData={tableHead}
          tableData={[...tableData]}
          isLoder={list.isLoding}
          isOrderingDisabled={true}
        />
      </div>
    </>
  );
}

export default Plans;
