import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { addPrefixOnNumber } from "../../utils/function";

function Counter({ from = 100, to = 2000 }) {
  const fromValue = typeof from === "string" ? parseFloat(from) : from;
  const toValue = typeof to === "string" ? parseFloat(to) : to;
  const ref = useRef();

  useEffect(() => {
    if (!isNaN(fromValue) && fromValue !== toValue) {
      const controls = animate(fromValue, toValue, {
        duration: 1,
        onUpdate(value) {
          if (typeof value === "number") {
            ref.current.textContent = addPrefixOnNumber(Math.round(value));
          }
        },
      });
      return () => controls.stop();
    } else {
      ref.current.textContent = addPrefixOnNumber(Math.round(toValue));
    }
  }, [fromValue, toValue]);

  return <p ref={ref} />;
}

export default Counter;
