import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import { fetchData } from "../../helpers/fetchData";
import { formData, tooltipData } from "../../utils/constant";
import { setUser } from "../../store/slices/userSlice";

const ProfileManage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  let intialPaylodState = {
    firstName: "",
    lastName: "",
    email: "",
    roleCode: "",
    image: "",
  };
  const [paylodData, setPaylodData] = useState({ ...intialPaylodState });
  const [imageError, setErrorImage] = useState(false);
  const [isButtonModal, setIsButtonModal] = useState(false);
  const [avtarOptions, setAvtarOptions] = useState([
    { imgae: "", name: "", _id: "" },
  ]);
  const [selectedAvatars, setSelectedAvatars] = useState({});
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (user) {
      setPaylodData((prev) => ({
        ...prev,
        firstName: user?.user?.firstName,
        lastName: user?.user?.lastName,
        email: user?.user?.email,
        image: user?.user?.image,
        roleCode: user?.user?.roleCode,
        phone: user?.user?.phone,
      }));
    }
  }, [user]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const updateProfile = async () => {
    let data = { ...paylodData };
    setShowWarning(false);

    if (!data.image instanceof File) {
      delete data.image;
    }
    setDisabled(true);
    if (
      !paylodData.email ||
      !paylodData.firstName ||
      !paylodData.lastName ||
      !paylodData.roleCode ||
      !paylodData.phone
    ) {
      toast.warn("All fields are required");
      setDisabled(false);
      setShowWarning(true);
      return;
    }
    let resp = await fetchData(
      "put",
      "admin/admin-edit",
      { body: data },
      formData
    );

    if (resp.status >= 200 && resp.status <= 300) {
      if (resp.data && resp.data.message) {
        toast.success(resp.data.message);
      } else {
        toast.success("Profile updated successfully!");

        // setPaylodData({
        //   image: "",
        //   fullname: {
        //     firstName: "",
        //     lastName: "",
        //   },
        //   phone: "",
        //   email: "",
        //   username: "",
        //   password: "",
        // });
      }
      setShowWarning(false);
      dispatch(setUser({ ...resp?.data?.data }));
    } else {
      if (resp.data && resp.data.message) {
        toast.error(resp.data.message);
      } else {
        toast.error("An error occurred while updating the profile.");
      }
    }
    setShowWarning(false);
    setDisabled(false);
  };

  const [file, setfile] = useState();
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setfile(file);

    if (file) {
      setErrorImage(false);
      setSelectedAvatars({});
    }
  };
  useEffect(() => {
    if (file) {
      setPaylodData((prev) => ({ ...prev, image: file }));
    }
  }, [file]);

  useEffect(() => {
    if (Object.keys(selectedAvatars).length) {
      setPaylodData((prev) => ({ ...prev, image: selectedAvatars.image }));
      setfile("");
      setIsButtonModal(false);
    }
  }, [selectedAvatars._id]);

  return (
    <>
      <h1 className="text-3xl font-bold my-2"> Profile</h1>
      <div className="relative bg-white rounded-lg shadow-md py-16 px-10 mb-6">
        {/* <div
          className="absolute top-4 right-4 px-4 py-2 bg-indigo-600 text-white text-xl cursor-pointer rounded-md"
          onClick={() => setIsEditMode(false)}
          data-tooltip-content={"Edit User"}
          data-tooltip-id={tooltipData.id}
        >
          <i className="fa-solid fa-pen-to-square"></i>
        </div> */}
        <div className="w-full max-w-4xl mx-auto space-y-20">
          <div className="grid grid-cols-3 gap-5">
            <div className="space-y-4">
              <div className="relative"></div>
            </div>
          </div>
        </div>
        <div className="flex gap-8 ">
          <div className="space-y-8 ">
            <div className="relative w-56 h-56 rounded-full overflow-hidden mx-auto border-2">
              <label htmlFor="imageInput">
                {!imageError ? (
                  <>
                    <img
                      src={
                        file
                          ? URL.createObjectURL(file)
                          : paylodData.image
                          ? paylodData.image
                          : "/images/avtar.svg"
                      }
                      alt=""
                      fill="true"
                      className="object-cover cursor-pointer h-full w-full"
                      onError={() => setErrorImage(true)}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={"/images/avtar.svg"}
                      alt=""
                      fill="true"
                      className="object-cover cursor-pointer h-full w-full"
                    />
                  </>
                )}
              </label>
              <input
                type="file"
                accept="image/*"
                id="imageInput"
                style={{ display: "none" }}
                onChange={(e) => handleImageChange(e)}
                disabled={isEditMode}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 w-[-webkit-fill-available] ">
            <div className="relative h-fit">
              <Input
                label={"First Name"}
                inputPlaceholder={"Enter First Name..."}
                inputName={"firstName"}
                isRequired={true}
                inputType={"text"}
                onChange={(e) =>
                  setPaylodData((prev) => ({
                    ...prev,
                    firstName: e.target.value,
                  }))
                }
                value={paylodData.firstName || ""}
                isDisabled={isEditMode}
                errorType={
                  showWarning && !paylodData.firstName ? "error" : false
                }
                errorText={
                  showWarning && !paylodData.firstName
                    ? "First name is required"
                    : ""
                }
              />
            </div>
            <div className="relative h-fit">
              <Input
                label={"Last Name"}
                inputPlaceholder={"Enter Last Name..."}
                inputName={"lastName"}
                isRequired={true}
                onChange={(e) =>
                  setPaylodData((prev) => ({
                    ...prev,
                    lastName: e.target.value,
                  }))
                }
                errorType={
                  showWarning && !paylodData.lastName ? "error" : false
                }
                errorText={
                  showWarning && !paylodData.lastName
                    ? "Last name is required"
                    : ""
                }
                inputType={"text"}
                value={paylodData.lastName || ""}
                isDisabled={isEditMode}
              />
            </div>
            <div className="relative h-fit">
              <Input
                label={"Email Address"}
                inputPlaceholder={"Enter Email..."}
                inputName={"email"}
                errorType={showWarning && !paylodData.email ? "error" : false}
                errorText={
                  showWarning && !paylodData.email ? "Email is required" : ""
                }
                isRequired={true}
                onChange={(e) =>
                  setPaylodData((prev) => ({ ...prev, email: e.target.value }))
                }
                inputType={"email"}
                value={paylodData.email || ""}
                toolTipLabel={"Not allowed to change the email address"}
                isDisabled={true}
              />
            </div>
            <div className="relative h-fit">
              <Input
                label={"Phone Number"}
                inputPlaceholder={"Enter Phone..."}
                inputName={"phone"}
                errorType={showWarning && !paylodData.phone ? "error" : false}
                errorText={
                  showWarning && !paylodData.phone
                    ? "Phone number is required"
                    : ""
                }
                isRequired={true}
                onChange={(e) =>
                  setPaylodData((prev) => ({ ...prev, phone: e.target.value }))
                }
                inputType={""}
                value={paylodData.phone || ""}
                isDisabled={false}
              />
            </div>
            <div className="relative h-fit">
              <Select
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                label="Select Role"
                placeholder="Select Role"
                dropdownData={[
                  { _id: 1, label: "Admin", value: "admin" },
                  { _id: 2, label: "User", value: "user" },
                ]}
                inputName={"roleCode"}
                onChange={(val) => {
                  setPaylodData((prev) => ({ ...prev, roleCode: val.value }));
                }}
                selectedValue={paylodData.roleCode || ""}
                isDisabled={isEditMode}
                // selectedValue={formik.values.status}
              />
            </div>
            {!isEditMode && (
              <div className="relative">
                <Input
                  label={"Password"}
                  inputPlaceholder={"Enter Password..."}
                  inputName={"password"}
                  onChange={(e) =>
                    setPaylodData((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  inputType={"password"}
                  value={paylodData.password || ""}
                />
              </div>
            )}
            {!isEditMode && (
              <div className="relative">
                <Input
                  label={" Confirm Password"}
                  inputPlaceholder={"Enter Confirm Password..."}
                  inputName={"confirmPassword"}
                  onChange={(e) =>
                    setPaylodData((prev) => ({
                      ...prev,
                      confirmpassword: e.target.value,
                    }))
                  }
                  inputType={"password"}
                  value={paylodData.confirmpassword || ""}
                />
              </div>
            )}
          </div>
        </div>
        {!isEditMode && (
          <div className="relative flex justify-center mt-6">
            <Button
              buttonLabel={"Save"}
              buttonClasses={"h-12 px-5 w-full max-w-xs"}
              buttonEffect={"filled"}
              buttonHasLink={false}
              buttonFunction={() => updateProfile()}
              buttonDisabled={disabled}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileManage;
