import React, { useState, useEffect, Fragment } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import AuthLayout from "./layouts/AuthLayout";
import DefaultLayout from "./layouts/DefaultLayout";
// import Login from "./pages/Login";

import { userType } from "./utils/constant";

// import ResourceList from "./pages/admin/resource/Resource";
// import ResourceAdd from "./pages/admin/resource/Manage/ResourceAdd";
// import ResourceEdit from "./pages/admin/resource/Manage/ResourceEdit";
// import EnquiryList from "./pages/admin/Enquiry/Enquiry";
// import Rate from "./pages/rate/Rate";
// import Upload from "./pages/employees/upload/Upload";
// import Resource from "./pages/resource/Resource";

// import EnquiryDetail from "./pages/admin/Enquiry/Manage/EnquiryDetails";
// import UserGuideEdit from "./pages/admin/userGuide/UserGuideManage/UserGuideEdit";
// import UserGuideList from "./pages/admin/userGuide/userGuide";
// import PrivacyPolicyList from "./pages/admin/privacyPolicy/privacyPolicy";
// import PrivacyPolicyEdit from "./pages/admin/privacyPolicy/PrivacyPolicyManage/PrivacyPolicyEdit";
// import { useSelector } from "react-redux";
import Loader from "./common/Loder";
// import UserGuide from "./pages/userGuide/UserGuide";
// import PrivacyPolicyAdd from "./pages/admin/privacyPolicy/PrivacyPolicyManage/PrivacyPolicyAdd";
// import CompanyEdit from "./pages/admin/company/Manage/CompanyEdit";
// import Employee from "./pages/admin/employee/Employee";
// import EmployeeAdd from "./pages/admin/employee/Manage/EmployeeAdd";
// import EmployeeEdit from "./pages/admin/employee/Manage/EmployeeEdit";
// import CompanyDetail from "./pages/admin/company/Details/CompanyDetails";
// import UserGuideView from "./pages/userGuide/View";
// import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
// import ResourceManage from "./pages/resource/ResourceManage";
// import Companyusers from "./pages/employees/Companyusers";
// import CompanyEmployees from "./pages/employees/CompanyEmployees";
// import NonEvaluatedEmployes from "./pages/employees/NonEvaluatedEmployes";
// import PositionCompetency from "./pages/PositionCompetency/PositionCompetency";

import Custom404 from "./common/Custom404";
import { useSelector } from "react-redux";
import Login from "./pages/login";
import DashBoard from "./pages/dashboard";
import Diary from "./pages/dashboard/diary/Index";
import Months from "./pages/dashboard/months/Index";
import Daily from "./pages/dashboard/daily/Index";
import Users from "./pages/dashboard/users/Index";
import UsersManage from "./pages/dashboard/users/manage/Manage";
import ProfileManage from "./pages/profile/Profile";
import Plans from "./pages/dashboard/plans/Index";
import PlansManage from "./pages/dashboard/plans/manage/Manage";
// import Rating from "./pages/employees/Rating";
// import EmailTemplate from "./pages/admin/emailTemplate/EmailTemplate";
// import EmailTemplateAdd from "./pages/admin/emailTemplate/Manage/EmailTemplateAdd";
// import EmailTemplateEdit from "./pages/admin/emailTemplate/Manage/EmailTemplateEdit";
// import Statistic from "./pages/Statistic";
// import CompanyEmployeeDetails from "./pages/employees/CompanyEmployeeDetails";
// import RatingWeight from "./pages/RatingWeight/ratingWeight";
// import Transaction from "./pages/admin/transaction/Transaction";
// import TransactionDetails from "./pages/admin/transaction/Details/TransactionDetails";
// import Subscription from "./pages/admin/subscription/Subscription";
// import Plan from "./pages/admin/plans/Plan";
// import PlanAdd from "./pages/admin/plans/Manage/PlanAdd";
// import Groups from "./pages/groups/Groups";
// import GroupAdd from "./pages/groups/Add";
// import PlanEdit from "./pages/admin/plans/Manage/PlanEdit";
// import SubscriptionDetails from "./pages/admin/subscription/Details";
// import StatisticRange from "./pages/StatisticRange/StatisticRange";
// import AdminStatisticRange from "./pages/admin/statisticRange/AdminStatisticRange";

// import PotentialOption from "./pages/admin/potentialOption/PotentialOption";
// import PotentialOptionEdit from "./pages/admin/potentialOption/Manage/PotentialOptionEdit";
// import PerformanceOption from "./pages/admin/performanceOption/PerformanceOption";
// import PerformanceOptionEdit from "./pages/admin/performanceOption/Manage/PerformanceOptionEdit";

const CreateRoutes = () => {
  const userData = useSelector((state) => state?.user?.user);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (userData || localStorage.getItem("auth_token")) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [userData]);

  window.addEventListener("auth_token", (e) => {
    const token = e.target.localStorage.auth_token;
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  });
  if (!isAuthenticated) {
    if (localStorage.getItem("auth_token")) {
      return <Loader />;
    }
  }
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {isAuthenticated ? (
            <>
              <Route path="/" element={<DefaultLayout />}>
                <Fragment>
                  <Route path="/" element={<Navigate to={"/dashboard"} />} />
                  <Route path="/dashboard" element={<DashBoard />} />
                  <Route path="/diary" element={<Diary />} />
                  <Route path="/diary/:userid" element={<Diary />} />
                  <Route path="/months" element={<Months />} />
                  <Route path="/months/:userid" element={<Months />} />
                  <Route path="/daily" element={<Daily />} />
                  <Route path="/daily/:userid" element={<Daily />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/profile" element={<ProfileManage />} />
                  <Route path="/users/manage" element={<UsersManage />} />
                  <Route path="/plans" element={<Plans />} />
                  <Route path="/plans/manage" element={<PlansManage />} />
                  <Route path="/plans/manage/:id" element={<PlansManage />} />

                  <Route path="/users/manage/:id" element={<UsersManage />} />
                </Fragment>
              </Route>
              <Route path="*" element={<Custom404 />} />
            </>
          ) : (
            <>
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/" element={<AuthLayout />}>
                <Route path="/" element={<Login />} />
                <Route path="/admin" element={<Login />} />
              </Route>
            </>
          )}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
