import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  addNoteAndTaskInMonth,
  getMonthList,
} from "../../../../services/monthService";
import moment from "moment";
import { toast } from "react-toastify";
import Note from "./Note";
import DailyTraking from "./DailyTracking";
import { months } from "../../../../utils/constant";
import { exportToCSV } from "../../../../utils/function";
import EventsNote from "./EventsNote";

const MonthlyGoalSection = ({
  currentYear,
  selectedMonth,
  selectedYear,
  selectedUser,
}) => {
  const notsRef = useRef(null);
  const [days, setdays] = useState([]);

  const [complete, setComplete] = useState({
    index: null,
    targetDay: null,
    isComplete: true,
  });

  const [dataForCsv, setDataForCsv] = useState([]);

  const getData = useCallback(() => {
    if (selectedYear && selectedMonth) {
      getMonthList({
        type: "monthly",
        subType: "daily",
        year: selectedYear?.toString(),
        month: (selectedMonth + 1)?.toString(),
        userId: selectedUser?.value,
      }).then((res) => {
        if (res && res?.docs?.status == 200) {
          const updatedDaysMap = new Map();

          res?.docs?.data.forEach((updatedDay) => {
            delete updatedDay?.month;
            delete updatedDay?.year;
            delete updatedDay?.type;
            updatedDaysMap.set(updatedDay.day, {
              ...updatedDay,
              day: Number(updatedDay.day),
              notes: updatedDay?.task.length
                ? updatedDay?.task?.map((task) => ({
                    ...task,
                    value: task.name,
                  }))
                : [],
            });
          });
          setdays((prevDays) => {
            updatedDaysMap?.forEach((item) => {
              delete item?.task;
              prevDays[Number(item?.day - 1)] = item;
            });
            setDataForCsv([...prevDays]);
            return [...prevDays];
          });
        } else {
        }
      });
    }
  }, [selectedYear, selectedMonth, selectedUser]);
  console.log({ days, dataForCsv });
  useEffect(() => {
    if (selectedUser.value) {
      getData();
    }
  }, [getData]);

  function noteAdd(data) {
    const new_data = {
      type: "monthly",
      subType: "daily",
      date: moment(new Date(selectedYear, selectedMonth, data?.day)).format(
        "YYYY-MM-DD"
      ),
      userId: selectedUser?.value,
    };

    if (data?._id) {
      new_data["name"] = data?.value;
      new_data["taskElementId"] = data?._id;
    } else {
      new_data["task[0][name]"] = data?.value;
    }

    setComplete({
      index: data?.note_index,
      targetDay: data?.day,
      isComplete: false,
    });
    addNoteAndTaskInMonth(new_data).then((res) => {
      if (res?.status === 201 || res?.status === 200) {
        setdays((pre) =>
          pre?.map((day) =>
            day?.day == Number(res?.data?.day)
              ? {
                  ...day,
                  ...res?.data,
                  notes: res?.data?.task?.map((task) => ({
                    ...task,
                    value: task.name,
                  })),
                }
              : day
          )
        );
        toast.success(res?.message);
      }
      setComplete({ index: null, targetDay: null, isComplete: true });
    });
  }
  const csvData = dataForCsv?.map((item, index) => {
    const date = moment(item?.dateObject)?.format("YYYY-MM-DD") || "";
    const taskNames = item?.notes?.map((note) => note?.name).join(", ") || "";
    const createdBy = item?.createdBy || "";
    const subType = item?.subType || "";
    const status = item?.status || "";
    const totalTime = item?.totalTime || "";
    // Add more properties as needed

    return { date, taskNames, createdBy, subType, status, totalTime };
  });
  // const csvData = dataForCsv?.map((item, index) => ({
  //   date: moment(item?.dateObject)?.format("YYYY-MM-DD") || "",
  //   task: item?.notes?.map((item) => item?.name).join(" , "),
  // }));
  const containerRef = useRef(null);

  const [selectedDateIndex, setSelectedDateIndex] = useState(
    new Date().getDate() - 1
  );

  useEffect(() => {
    if (
      selectedDateIndex >= 0 &&
      selectedDateIndex < 30 &&
      containerRef.current &&
      containerRef.current.childNodes
    ) {
      const element = containerRef.current.childNodes[selectedDateIndex];
      if (element) {
        const containerScrollTop = containerRef.current.scrollTop;
        const elementOffsetTop = element.offsetTop;
        containerRef.current.style.scrollBehavior = "smooth";
        containerRef.current.scrollTop =
          elementOffsetTop - containerScrollTop - 450;
      }
    }
  }, [selectedDateIndex, containerRef?.current?.childNodes]);
  const [dailyResponse, setDailyResponse] = useState([]);
  return (
    <div className="calendar w-full space-y-5">
      <div className="flex items-center justify-center">
        <div className="text-indigo-900 font-bold text-center text-3xl capitalize">
          {months[selectedMonth]} {selectedYear} Monthly Goals
        </div>
      </div>
      <div className="flex gap-3 w-full">
        <div className="w-full flex-grow-1">
          <div className="h-10 bg-indigo-900 text-white flex items-center justify-center mb-5 rounded px-4">
            Daily Tracking
            {/* <span
              className="ml-auto cursor-pointer"
              onClick={() => {
                if (csvData.length) {
                  exportToCSV(csvData, "daily_tracking.csv");
                }
              }}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Export CSV"
            >
              <i className="fa-solid fa-download" />
            </span> */}
          </div>
          <div
            className="scrollbar overflow-y-auto  h-[60vh]"
            ref={containerRef}
          >
            <DailyTraking
              monthIndex={selectedMonth}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              days={days}
              setdays={setdays}
              onSave={(data) => noteAdd(data)}
              saveComplete={complete}
              selectedUser={selectedUser}
              setDailyResponse={setDailyResponse}
            />
          </div>
        </div>
        <div
          className="flex-grow-0 h-fit w-[1000px] flex flex-col gap-y-5"
          ref={notsRef}
        >
          <Note
            title="Monthly Goal"
            type="monthly"
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            selectedUser={selectedUser}
            dailyResponse={dailyResponse}
          />
          <EventsNote
            title="Events"
            type="event"
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            abbBtnTooltipLabel={"Add event"}
            selectedUser={selectedUser}
          />
          <EventsNote
            title="Birthday"
            type="birthday"
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            abbBtnTooltipLabel={"Add birthday"}
            selectedUser={selectedUser}
          />
        </div>
      </div>
    </div>
  );
};

export default MonthlyGoalSection;
