import { fetchData } from "../helpers/fetchData";

export const getDailyList = async (params) => {
  try {
    const res = await fetchData("get", "admin/task-list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addNoteAndTaskInDaily = async (params) => {
  try {
    const res = await fetchData(
      `${params?.taskElementId ? "put" : "post"}`,
      `${params?.taskElementId ? "admin/edit-task" : "admin/add"}`,
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addSubTaskInDaily = async (params) => {
  try {
    const res = await fetchData(
      "put",
      `${
        params?.subTaskElementId
          ? "admin/edit-subtask"
          : params?.identicalId
          ? "admin/edit-all-subtask"
          : "admin/add-subtask"
      }`,
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
