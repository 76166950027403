import { combineReducers } from "redux";
import userReducer from "./userSlice";
import roleSlice from "./roleSlice";
import settingReducer from "./settingsSlice";

const rootReducer = combineReducers({
  user: userReducer,
  roles: roleSlice,
  setting: settingReducer,
});

export default rootReducer;
