import React, { useEffect, useState } from "react";
import Input from "../../../../components/form/Input";
import Button from "../../../../components/form/Button";
import { useParams } from "react-router-dom";
import HeadingWrapper from "../../../../components/common/HeadingWrapper";
import { toast } from "react-toastify";
import { withTryCatch } from "../../../../utils/function";
import { fetchData } from "../../../../helpers/fetchData";
import { useNavigate } from "react-router-dom";
import InputFile from "../../../../components/form/InputFile";
import Select from "../../../../components/form/Select";
function UsersManage() {
  const [paylodData, setPaylodData] = useState({});
  const { id } = useParams();
  const [isDissabled, setIsDissabled] = useState(false);
  const navigate = useNavigate();
  const [isLoder, setIsLoder] = useState();

  const handleSubmit = withTryCatch(async () => {
    setIsDissabled(true);
    if (
      !paylodData.email ||
      !paylodData.firstName ||
      !paylodData.lastName ||
      !paylodData.roleCode
    ) {
      toast.warn("All fields are required");
      setIsDissabled(false);
      return;
    }
    if (paylodData.password !== paylodData.confirmpassword) {
      toast.warn("Password and confirm password must be the same");
      setIsDissabled(false);
      return;
    }
    if (!id) {
      if (!paylodData.password) {
        toast.warn("Password is required");
        setIsDissabled(false);
        return;
      }
    }
    let obj = {
      method: id ? "put" : "post",
      endpoint: id ? "admin/user-edit" : "admin/user-add",
    };
    let resp = await fetchData(
      obj.method,
      obj.endpoint,
      {
        body: { ...paylodData, id: id ? id : "" },
      },
      "form-data"
    );
    if (resp?.data?.status === 200) {
      toast.success(resp?.data?.message);
      navigate("/users");
    } else {
      toast.warn(resp?.data?.message);
    }
    setIsDissabled(false);
  });

  const getUsersDetails = withTryCatch(async (id) => {
    setIsLoder(true);
    let resp = await fetchData("get", "/admin/user-details", {
      queries: { id: id },
    });
    if (resp.data.status === 200) {
      setPaylodData((prev) => ({
        ...prev,
        firstName: resp?.data?.data?.firstName,
        lastName: resp?.data?.data?.lastName,
        email: resp?.data?.data?.email,
        image: { image: resp?.data?.data?.image },
        roleCode: resp?.data?.data?.roleCode,
        phone: resp?.data?.data?.phone,
      }));
    } else {
      toast.warn(resp.data.message);
    }
    setIsLoder(false);
  });
  useEffect(() => {
    if (id) {
      getUsersDetails(id);
    }
  }, [id]);
  return (
    <>
      <HeadingWrapper title={id ? "Edit User" : "Add User"} />
      <div className=" bg-white rounded-md shadow-sm p-4">
        <div className="grid grid-cols-2 gap-4 ">
          <div className="relative">
            <Input
              label={"First Name"}
              inputPlaceholder={"Enter First Name..."}
              inputName={"firstName"}
              inputType={"text"}
              onChange={(e) =>
                setPaylodData((prev) => ({
                  ...prev,
                  firstName: e.target.value,
                }))
              }
              value={paylodData.firstName || ""}
            />
          </div>
          <div className="relative">
            <Input
              label={"Last Name"}
              inputPlaceholder={"Enter Last Name..."}
              inputName={"lastName"}
              onChange={(e) =>
                setPaylodData((prev) => ({
                  ...prev,
                  lastName: e.target.value,
                }))
              }
              inputType={"text"}
              value={paylodData.lastName || ""}
            />
          </div>
          <div className="relative">
            <Input
              label={"Email Address"}
              inputPlaceholder={"Enter Email..."}
              inputName={"email"}
              onChange={(e) =>
                setPaylodData((prev) => ({ ...prev, email: e.target.value }))
              }
              inputType={"email"}
              isDisabled={id ? true : false}
              value={paylodData.email || ""}
            />
          </div>
          <div className="relative h-fit">
            <Input
              label={"Phone Number"}
              inputPlaceholder={"Enter Phone..."}
              inputName={"phone"}
              onChange={(e) =>
                setPaylodData((prev) => ({ ...prev, phone: e.target.value }))
              }
              inputType={""}
              value={paylodData.phone || ""}
              isDisabled={false}
            />
          </div>
          <div className="relative">
            <Select
              xPlacement={"left"}
              dropdownButtonClass={"!bg-white"}
              label="Select Role"
              placeholder="Select Role"
              dropdownData={[
                { _id: 1, label: "Admin", value: "admin" },
                { _id: 2, label: "User", value: "user" },
              ]}
              inputName={"roleCode"}
              onChange={(val) => {
                setPaylodData((prev) => ({ ...prev, roleCode: val.value }));
              }}
              selectedValue={paylodData.roleCode || ""}
              // selectedValue={formik.values.status}
            />
          </div>
          <div className="relative">
            <Input
              label={"Password"}
              inputPlaceholder={"Enter Password..."}
              inputName={"password"}
              onChange={(e) =>
                setPaylodData((prev) => ({ ...prev, password: e.target.value }))
              }
              inputType={"password"}
              value={paylodData.password || ""}
            />
          </div>
          <div className="relative">
            <Input
              label={" Confirm Password"}
              inputPlaceholder={"Enter Confirm Password..."}
              inputName={"confirmPassword"}
              onChange={(e) =>
                setPaylodData((prev) => ({
                  ...prev,
                  confirmpassword: e.target.value,
                }))
              }
              inputType={"password"}
              value={paylodData.confirmpassword || ""}
            />
          </div>

          <div className="relative ">
            <InputFile
              label={"Select Image"}
              inputName={"image"}
              inputButtonPlaceholder={"Select"}
              inputValue={paylodData.image}
              onChange={(e) => {
                setPaylodData((prev) => ({
                  ...prev,
                  image: e?.target?.files[0],
                }));
              }}
            />
          </div>
        </div>
        <div className="relative ml-auto">
          <Button
            buttonLabel={id ? "Edit User" : "Add User"}
            buttonIcon={"fa-solid fa-plus"}
            buttonIconPosition={"left"}
            buttonClasses={"!ml-auto"}
            buttonDisabled={isDissabled}
            buttonFunction={() => {
              handleSubmit();
            }}
          />
        </div>
      </div>
    </>

    // <HeadingWrapper title={id ? "Edit User" : "Add User"}>
    // {/* </HeadingWrapper> */}
  );
}

export default UsersManage;
