import React, { useEffect, useState } from "react";
import { exportToCSV, withTryCatch } from "../../../utils/function";
import { toast } from "react-toastify";
import { fetchData } from "../../../helpers/fetchData";
import useTryCatch from "../../../components/common/TryCatchWrapper";
import HeadingWrapper from "../../../components/common/HeadingWrapper";
import Select from "../../../components/form/Select";
import Table from "../../../components/elements/table/Table";
import { momentLocalizer, Calendar, Views } from "react-big-calendar";
import moment from "moment";
import Modal from "../../../common/Modal";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import MenuTabs from "../../../components/common/menutab/MenuTab";
import DailyGoal from "./listView/ListView";
import Button from "../../../components/form/Button";

function Daily() {
  const [list, setList] = useState({ data: [], isLoding: true });
  const [selectedUser, setSelecetdUser] = useState({});
  const [usersDropDown, setUsersDropDown] = useState([]);
  const myViews = [Views.MONTH];
  const [selectedEvents, setSelectedEvents] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { userid } = useParams();
  const getUsers = withTryCatch(async () => {
    let users = await fetchData("get", "admin/user-list", {
      queries: { limit: 500 },
    });
    if (users.data.docs.status === 200) {
      let updatedUser = users?.data?.docs?.data?.map((item, index) => ({
        _id: item._id,
        label:
          (item?.firstName ? item?.firstName : "") +
          " " +
          (item?.lastName ? item?.lastName : ""),
        value: item._id,
      }));
      setUsersDropDown(updatedUser);
      if (!userid) {
        setSelecetdUser(updatedUser[0]);
      } else {
        setSelecetdUser({ value: userid });
      }
    } else {
      toast.warn(users?.data?.docs?.message || "Something went wrong");
    }
  });
  const getTasks = withTryCatch(async () => {
    setList((prev) => ({ ...prev, isLoding: true, data: [] }));
    let resp = await fetchData("get", "admin/task-list", {
      queries: {
        year: "2024",
        type: "daily",
        userId: selectedUser?.value || userid,
      },
    });
    if (resp?.data?.docs?.status === 200) {
      setList((prev) => ({
        ...prev,
        isLoding: false,
        data: resp?.data?.docs?.data,
      }));
    } else {
      toast.warn(resp?.data?.docs?.message);
    }
    setList((prev) => ({ ...prev, isLoding: false }));
  });

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (selectedUser.value || userid) {
      getTasks();
    }
  }, [selectedUser, userid]);

  const localizer = momentLocalizer(moment);
  const [newSelectedYear, setNewSelectedYear] = useState("");
  const myTaskList =
    list?.data &&
    list?.data?.map((item, index) => ({
      id: item?._id,
      title: item?.task[0]?.name,
      start: new Date(`${item?.year}-${item?.month}-${item?.day}`),
      end: new Date(`${item?.year}-${item?.month}-${item?.day}`),
      description: "Team meeting",
      location: "Conference Room A",
      color: "blue",
      index: index,
      subTask: item?.task[0]?.subTask,
    }));
  const eventStyleGetter = (event, start, end, isSelected) => {
    // Decrease the width of each event
    return {
      style: {
        width: "80%", // Adjust the width as needed
        margin: "0 auto", // Center the event horizontally
      },
    };
  };
  const [active, setActive] = useState(0);

  const menuTab = [
    {
      _id: 1,
      label: "List View",
    },
    {
      _id: 2,
      label: "Calander View",
    },
  ];
  const csvData = list?.data?.map((item, index) => ({
    Date: moment(item?.dateObject).format("YYYY-MM-DD"),

    Task: item?.task[0]?.name,
    ["Sub Task"]: item?.task[0]?.subTask
      .map((subTask) => subTask?.name)
      .join(" , "),
  }));
  return (
    <>
      <div className="">
        <div className="gap-4 flex items-center">
          <div className="relative">
            <HeadingWrapper title={"Daily"} />
          </div>
          <div className="w-1/3 !ml-auto mb-4">
            <Select
              xPlacement={"left"}
              dropdownButtonClass={"!bg-white  !w-full"}
              label="Select User"
              placeholder="Select user"
              dropdownData={usersDropDown}
              onChange={(val) => {
                setSelecetdUser(val);
              }}
              selectedValue={selectedUser.value}
              inputName="noOfEmployees"
            />
          </div>
          <div className="relative">
            <Button
              buttonLabel={"Export"}
              buttonIcon={"fa-solid fa-download"}
              buttonIconPosition={"left"}
              buttonClasses={"!w-fitt "}
              buttonHasLink={false}
              // buttonLink={"/users/manage"}
              buttonFunction={() => {
                if (csvData.length) {
                  exportToCSV(csvData, "daily_task.csv");
                }
              }}
            />
          </div>
        </div>

        <div className="w-64 mb-4">
          <MenuTabs menu={[...menuTab]} setActive={setActive} active={active} />
        </div>
        {active === 1 ? (
          <div className="bg-white p-4 rounded-md shadow-md">
            <div>
              <Calendar
                localizer={localizer}
                // events={myEventsList}
                views={myViews}
                events={myTaskList}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                eventPropGetter={eventStyleGetter}
                onSelectEvent={(task) => {
                  setSelectedEvents(task);
                  setIsModalOpen(true);
                }}
                components={{ toolbar: Toolbar }}
              />
            </div>
          </div>
        ) : (
          <>
            <DailyGoal selectedUser={selectedUser} />
          </>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setSelectedEvents({});
        }}
        isCancelBtnEnable={false}
        isSucessBtnEnable={false}
        modalClass={"!w-1/2 "}
      >
        <h1 className="text-xl font-bold relative text-center">
          {selectedEvents?.title}
        </h1>
        <div className="flex flex-col my-2 gap-2">
          {selectedEvents?.subTask?.map((item, index) => (
            <div className="flex items-center gap-2 justify-between border-2 rounded-lg border-slate-300 px-4 py-2">
              <div className=" flex items-center justify-center gap-2">
                <span className="w-2 h-2 rounded-full bg-black"></span>
                <p>{item.name}</p>
              </div>
              <div>
                <i
                  class={` w-8 h-8 flex items-center justify-center ${
                    item.completed === 1
                      ? "bg-indigo-600 fa-solid fa-check"
                      : "bg-red-600 fa-solid fa-xmark"
                  }  rounded-full text-white `}
                ></i>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}
const Toolbar = ({ localizer: { messages }, label, date, onNavigate }) => {
  const navigate = (action) => {
    let newDate;
    if (action === "PREVIOUS") {
      // Go to the previous month
      newDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      onNavigate("prev", newDate);
    } else if (action === "NEXT") {
      // Go to the next month
      newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      onNavigate("next", newDate);
    }
  };
  const years = [];
  for (let i = date.getFullYear() - 5; i <= date.getFullYear() + 5; i++) {
    years.push(i);
  }
  const handleYearChange = (event) => {
    const selectedYear = parseInt(event.target.value);
    const newDate = new Date(selectedYear, date.getMonth(), 1);
    // Invoke the onNavigate function with the new date
    // newSelectedYear = newDate;
    onNavigate("prev", newDate);
  };
  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button
          type="button"
          className="btn btn-control"
          onClick={() => navigate("PREVIOUS")}
        >
          <i className="fa fa-arrow-left"></i> Prev
        </button>
      </span>
      <span className="rbc-btn-group">
        <button
          type="button"
          className="btn btn-control"
          onClick={() => navigate("NEXT")}
        >
          {messages.next} <i className="fa fa-arrow-right"></i>
        </button>
      </span>
      <span className="rbc-toolbar-label">{label}</span>
      <span className="rbc-btn-group">
        <select
          className="form-select"
          value={date.getFullYear()}
          onChange={handleYearChange}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </span>
    </div>
  );
};

export default Daily;
